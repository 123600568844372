import { Component } from "react"
import AOS from'aos';
import "aos/dist/aos.css";
import Video from './images/video.mp4'
import euro from './images/euro.jpg'

AOS.init({
    delay: 200, // values from 0 to 3000, with step 50ms
    duration: 1500, // values from 0 to 3000, with step 50ms
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
  });



 class Despre extends Component{
  
      render(){
      return(

      <>

<div className="relative pt-16 pb-32 flex content-center items-center justify-center" style={{minHeight: '75vh'}} id="despre_noi">
{/* <!-- Background Image --{">"} */}
<div
  className="absolute top-0 w-full h-full bg-center bg-cover"
  >
    <video src={Video} autoPlay loop style={{opacity:'0.5'}} playsInline />
  {/* <!-- Overlay --> */}
  <span id="blackOverlay" class="w-full h-full absolute opacity-75 bg-black"></span>
</div>
{/* <!-- Text Over Main Background --> */}
<div className="container3 relative mx-auto">
  <div className="items-center flex flex-wrap">
    <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto ">
      <div className="pr-12">
        <h1 className="text-gray-500 font-semibold text-5xl" data-aos="fade-up">
          Despre noi
        </h1>
        <p className="mt-4 text-lg text-gray-500" data-aos="fade-right" data-aos-delay="1000">
        Administratorul și directorul societății este expert contabil și auditor financiar, cu o experiență de peste 20 de ani în asigurarea de servicii financiar-contabile și fiscale, în numeroase domenii de activitate și 13 ani în domeniul auditului.
        </p>
      </div>
    </div>
  </div>
</div>
{/* <!-- Making Polygon Shape in Background --> */}
<div
  className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
  style={{height: '70px', transform: 'translateZ(0)'}}
>
  <svg
    className="absolute bottom-0 overflow-hidden"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    version="1.1"
    viewBox="0 0 2560 100"
    x="0"
    y="0"
  >
    <polygon
      className="text-gray-300 fill-current"
      points="2560 0 2560 100 0 100"
    ></polygon>
  </svg>
</div>
</div>
{/* <!-- End of Splash Page --> */}

{/* <!-- Services Section --> */}
<section className="pb-20 bg-gray-300 -mt-24">
{/* <!-- 3 Card Section --> */}
<div className="container3 mx-auto px-4">
  <div className="flex flex-wrap">
    {/* <!-- Card --> */}
    <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4" data-aos="fade-right" data-aos-delay="1200">
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
        <div className="px-4 py-5 flex-auto">
          <div className="text-white p-3 inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
            {/* <!-- Icon --> */}
            <i className="fas fa-book"></i>
          </div>
          {/* <!-- Text Box --> */}
          <h6 className="text-xl font-semibold">Istoric</h6>
          <h3>Ne-am înființat în 2019</h3>
          <p className="mt-2 mb-4 text-gray-600">
          Corect Audit Consulting SRL este membră a Corpului Experților Contabili și Contabililor Autorizați din România (CECCAR), Camere, Camerei Auditorilor Financiari din România (CAFR) si a Autoritatii pentru Supravegherea Publica a activitatii de audit statutar (ASPAAS).
          </p>
        </div>
      </div>
    </div>
    {/* <!-- Card --> */}
    <div className="w-full md:w-4/12 px-4 " data-aos="fade-up" data-aos-delay="1400">
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
        <div className="px-4 py-5 flex-auto">
          <div className="text-white p-3  inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
            {/* <!-- Icon --> */}
            <i className="fas fa-atom"></i>
          </div>
          {/* <!-- Text Box --> */}
          <h6 className="text-xl font-semibold">Scopul nostru</h6>
          <p className="mt-2 mb-4 text-gray-600">
          Societatea va crește alături de clienții săi și va reuși să obțină calificativul de excelență în servicii de contabilitate și audit. 
Corect Audit Consulting SRL iși dorește să fie alături de clienții săi nu doar din punct de vedere fiscal ci și să construiască parteneriate care să genereze un plus de valoare pentru ambele părți.
          </p>
        </div>
      </div>
    </div>
    {/* <!-- Card --> */}
    <div className="pt-6 w-full md:w-4/12 px-4 " data-aos="fade-left" data-aos-delay="1600">
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
        <div className="px-4 py-5 flex-auto">
          <div className="text-white p-3  inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
            {/* <!-- Icon --> */}
            <i className="fas fa-user-tie"></i>
          </div>
          {/* <!-- Text Box --> */}
          <h6 className="text-xl font-semibold">Profesioniști</h6>
          <p className="mt-2 mb-4 text-gray-600">
          Contabilii pe care vi-i punem la dispoziție au calități esențiale:
          <ul style ={ {paddingLeft:'4%'}}>
               <li> știință și competență</li>
               <li> profesionalism </li>
               <li> moralitate și integritate</li>
               <li> flexibilitate și informare</li>
                <li> comunicare
                </li>
           </ul>
          </p>
        </div>
      </div>
    </div>
  </div>

  {/* <!-- Text Box and Card Container --> */}
  <div className="flex flex-wrap items-center mt-32">
    {/* <!-- Text Box Container --> */}
    <div className="w-full md:w-5/12 px-4 mr-auto ml-auto" data-aos="zoom-in-right">
      {/* <!-- Text Box --> */}
      <div className="text-gray-600 p-3  inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
        {/* <!-- Icon --> */}
        <i className="fas fa-euro text-xl"></i>
      </div>
      <h3 className="text-3xl mb-2 font-semibold leading-normal text-white">
        Visează, plănuiește, realizează
      </h3>
      <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-500">
       Consultanță Accesare Fonduri Europene Nerambursabile
      </p>
      <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-500">
        Echipa Corect Audit te ajută să identifici cele mai bune soluții și să îți lansezi afacerea dorită!
      </p>
      <a
        href="/fonduri"
        className="font-bold text-gray-900 mt-8 "
        >
        <i className="fas fa-exclamation-circle text-xl"></i>
        Întreabă un consultant
      </a>
    </div>

    {/* <!-- Image Cap & Text Card --> */}
    <div className="w-full md:w-4/12 px-4 mr-auto ml-auto" data-aos="flip-left" data-aos-delay="1000">
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-orange-500">
        {/* <!-- Image --> */}
        <img
          alt="..."
          src={euro}
          className="w-full align-middle rounded-t-lg"
          style={{width:'100%',  height:'50%'}}
        />
        <blockquote className="relative p-8 mb-4">
    
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 583 95"
            className="absolute left-0 w-full block"
            style={{height: '95px',top: '-94px'}}
          >
            <polygon
              points="-30,95 583,95 583,65"
              className="text-orange-500 fill-current"
            ></polygon>
          </svg>
          {/* <!-- Text Box --> */}
          <h4 className="text-xl font-bold text-white">
           Expertiză în Fonduri Europene
          </h4>
          <p className="text-md font-light mt-2 text-white">
           Conducem clientul pe drumul spre accesarea corectă și bine gândită a fondurilor.
          </p>
        </blockquote>
      </div>
    </div>
  </div>
</div>
</section>

 </>
 );}

}


export default Despre;