export const MenuItems = [
    {
      title: 'Contabilitate',
      path: '/contabilitate',
      cName: 'dropdown-link'
     
    },
    {
      title: 'Audit',
      path: '/audit',
      cName: 'dropdown-link'
    },
    {
      title: 'Analiză financiară',
      path: '/analiza',
      cName: 'dropdown-link'
    },
    {
      title: 'Consultanță fiscală',
      path: '/consultanta',
      cName: 'dropdown-link'
    },
   
    {
      title: 'Salarizare',
      path: '/salarizare',
      cName: 'dropdown-link' 
    }
  ];

  