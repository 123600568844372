
import React, { Component } from 'react';
import './style/design_audit.css';
import Modal from '../Modal';
import Backdrop from './Backdrop';
import 'react-toastify/dist/ReactToastify.css';
 import {Helmet} from 'react-helmet';
 import { ToastContainer, toast } from 'react-toastify';


 
const customId = "custom-id-yes";
const customIdd = "custom-id-yess";
const customIddd = "custom-id-yesss";
const notify = () => toast.error("Verificati toate campurile inainte de a continua!", {
  toastId: customId,
  position: toast.POSITION.TOP_CENTER
});

const notifyy = () => toast.warn("Va rugam sa confirmati ca ati citit Termenii si Politica de confidentialitate!", {
    toastId: customIdd,
    delay:2000,
    position: toast.POSITION.TOP_CENTER
  });
  const notifyyy = () => toast.success("Cererea dumneavoastra a fost inregistrata cu succes!", {
    toastId: customIddd,
    position: toast.POSITION.TOP_CENTER
    
  });
  var printError = (elemId,hintMsg) =>{
    document.getElementById(elemId).innerHTML = hintMsg;
};
let  { numeErr, prenErr , emailErr, telErr} = true;

 class Contabilitate extends Component{
 
  state = {
    modalIsOpen: false,
    showBlock: false
  };

  showModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };



  
   
  validateForm() {
        
    var numesoc = document.contactForm.nume_soc.value;
    var nume = document.contactForm.nume.value;
    var pren_ = document.contactForm.pren.value;
    var email_ = document.contactForm.email.value;
    var tel = document.contactForm.tel.value;
    let checkbox = false;
    var nr_sal= document.getElementById("nr_sal").value;
    var subject=document.getElementById("subject").value;
    
  
    
    var  numesocErr  = numeErr = prenErr = emailErr= telErr=true;
    
    //Validate numesoc
    if(numesoc === "") {
        
        printError("numesocErr", "Completeaza numele societatii");
    } else {
        var regex = /^[0-9a-zA-Z\s]+$/;                
        if(regex.test(numesoc) === false) {
            printError("numesocErr", "Completeaza cu un nume valid");
        } else {
            printError("numesocErr", "");
           numesocErr = false;
        }
    }

    //Validate pren
    if(pren_ === "") {
        printError("prenErr", "Completeaza prenumele");
    } else {
         regex = /^[a-zA-Z\s]+$/;                
        if(regex.test(pren_) === false) {
            printError("prenErr", "Completeaza cu un prenume valid");
        } else {
            printError("prenErr", "");
           prenErr = false;
        }
    }
    // Validate name
    if(nume === "") {
        printError("numeErr", "Completeaza numele");
    } else {
         regex = /^[a-zA-Z\s]+$/;                
        if(regex.test(nume) === false) {
            printError("numeErr", "Completeaza cu un nume valid");
        } else {
            printError("numeErr", "");
           numeErr = false;
        }
    }
    
    // Validate email address
    if(email_ === "") {
        printError("emailErr", "Completeaza email-ul");
    } else {
         regex = /^\S+@\S+\.\S+$/;
        if(regex.test(email_) === false) {
             printError("emailErr", "Completeaza cu o adresa de email valida");
           
             window.history.go(-1);
        } else{
            printError("emailErr", "");
            emailErr = false;
        }
    }
    
    if(tel === "") {
        printError("telErr", "Completeaza telefonul tau");
    } else {
        regex = /^[0-9]+$/;                
        if(regex.test(tel) === false ){//|| tel[0] !== '0' || tel[1] !== '7' || tel.length !== 10) {
            printError("telErr", "Completeaza cu un numar de telefon valid");
        } else{
            if(tel[0] !== '0' || tel[1] !== '7'){
                printError("telErr", "Numarul trebuie sa inceapa cu '07'");
            }else{
                if(tel.length !== 10){
                    printError("telErr","Numarul trebuie sa fie format din 10 cifre, nu din " + tel.length + " cifre");
                }else{
                    printError("telErr", "");
                   telErr = false;
                  
                }
            } 
        }
    }

    if(document.getElementById('agree').checked){
        checkbox = true;
     }else { 
        //  alert('Va rugam sa confirmati ca ati citit Termenii si Politica de confidentialitate!'); 
        notifyy();
    }
    

    
    if((numesocErr || numeErr || prenErr || emailErr || telErr) === false && checkbox === true) {
     
        if( document.getElementById("popup").style.display="block"){
      
            var dataPreview = 
         
            "Numele societatii: \n" + numesoc + "\n\n" +
             "Nr. salariati:  \n" + nr_sal + "\n\n" +
            "Numele tau: \n" + nume + "\n\n" +
            "Prenumele tau: \n" + pren_ + "\n\n" +
            "Email-ul tau: \n" + email_ + "\n\n" +
            "Telefonul tau: \n" + tel + "\n\n"+
            "Mesajul tau: \n" + subject +"\n\n\n";

        var popup_text= document.getElementById("populate");
        popup_text.value = dataPreview;
        
        }
        else{
            alert("Nu se poate");
        }
     } else {
        //  alert("Verificati toate campurile inainte de a continua!");
    
       notify();
   
     }
    };
    
  
    
    
    sendEmail() {
        // let Email = { send: function (a) { return new Promise(function (n, e) { a.nocache = Math.floor(1e6 * Math.random() + 1), a.Action = "Send"; var t = JSON.stringify(a); Email.ajaxPost("https://smtpjs.com/v3/smtpjs.aspx?", t, function (e) { n(e) }) }) }, ajaxPost: function (e, n, t) { var a = Email.createCORSRequest("POST", e); a.setRequestHeader("Content-type", "application/x-www-form-urlencoded"), a.onload = function () { var e = a.responseText; null != t && t(e) }, a.send(n) }, ajax: function (e, n) { var t = Email.createCORSRequest("GET", e); t.onload = function () { var e = t.responseText; null != n && n(e) }, t.send() }, createCORSRequest: function (e, n) { var t = new XMLHttpRequest; return "withCredentials" in t ? t.open(e, n, !0) : "undefined" != typeof XDomainRequest ? (t = new XDomainRequest).open(e, n) : t = null, t } };
        var numesoc = document.contactForm.numesoc.value;
        var nume = document.contactForm.nume_.value;
        var pren_ = document.contactForm.pren_.value;
        var email_ = document.contactForm.email_.value;
        var tel = document.contactForm.tel.value;
        var nr_sal= document.getElementById("nr_sal").value;
        var subject=document.getElementById("subject").value;
        
      
        var dataPreview = 
    
        "Numele societatii: \n" + numesoc + "\n\n" +
         "Nr. salariati:  \n" + nr_sal + "\n\n" +
        "Numele tau: \n" + nume + "\n\n" +
        "Prenumele tau: \n" + pren_ + "\n\n" +
        "Email-ul tau: \n" + email_ + "\n\n" +
        "Telefonul tau: \n" + tel + "\n\n"+
        "Mesajul tau: \n" + subject +"\n\n\n";
      window.Email.send({
            SecureToken: "1c30e605-e052-4676-8d2e-61bdab667885",
            To:'mariaflavia8288@gmail.com, cristina.ciubuc@corectaudit.ro',
            From: "fonceala@gmail.com",
            Subject: "no-reply",
            Body: "Cererea dumneavoastra a fost inregistrata!"+ "\n\n\n\n" + dataPreview,
        }).then(function (message) { 
            // alert("Cererea dumneavoastra a fost inregistrata cu succes!");
             notifyyy();
          
        //   window.history.go(-1);
            
          });
    }

  render(){
  return (
    <>
    
    <section id="includedContentConta">
      <article id="despre-audit">
        <h2 id="titlu_contabilitate"> <em style={{color: 'violet'}}>Servicii de contabilitate</em> acoperite de echipa Corect Audit Consulting SRL</h2>
        <p id="titlu_contab"> Corect Audit Consulting ofera <strong> o gama completa de <em style={{color: 'violet'}}>servicii  de contabilitate </em>si fiscalitate </strong>, adaptate intr-un mod cat mai flexibil necesitatilor clientilor nostri.
        </p>
        <p id="paragraf_mare">
        
        <ul >
          <li>Analiza si inregistrarea documentelor primare conform reglementarilor contabile si a necesitatilor de raportare manageriala</li>
          <li>descarcarea din gestiune a stocurilor (pe baza evidentei operative a clientului)</li>
          <li>inregistrarea deconturilor de cheltuieli si a salariilor personalului</li>
          <li>inregistrarea operatiunilor de imprumut si de leasing</li>
          <li>inregistrarea extraselor bancare si a operatiunilor cu numerar</li>
          <li>inregistrarea lunara a estimarilor contabile de cheltuieli / venituri (accruals-uri), reluarea cheltuielilor sau a veniturilor in avans</li>
          <li>gestiunea registrului mijloacelor fixe si rularea regulata a amortizarii contabile/ fiscal</li>
          <li>alocarea platilor/ incasarilor </li>
          <li>reevaluarea lunara a conturilor monetare in devize</li>
          <li> operatiuni specifice TVA: regimul de TVA la incasare/inchidere TVA</li>
          <li>operatiuni contabile de inchidere a perioadei: inregistrarea impozitelor directe, definitivarea balantei de verificare
          </li>
          <li>
            intocmirea / validarea jurnalelor de TVA
          </li>
          <li>intocmirea si depunerea declaratiilor fiscal periodice aferente impozitelor directe, TVA si altele</li>
          <li>extragerea / procesarea / intocmirea raportarilor lunare catre Grup, conform cerintelor si calendarului agregate</li>
          <li>
            intocmirea situatiilor financiare anuale si a raportarilor contabile semestriale</li>
          <li>
            diverse formalitati efectuate cu organele fiscale , alte raportari si servicii conexe agreate.</li>
        </ul>
        </p>
      
        {/* <footer className="entry-meta"> */}
          {/* <div class="tagcloud" style="display: block;">
  
  <a href="https://corectaudit.ro/tag/audit">audit</a>
   <a href="https://corectaudit.ro/tag/contabilitate-corectaudit">contabilitate-corectaudit</a> 
   <a href="https://corectaudit.ro/tag/audit-financiar">audit financiar</a>
    <a href="https://corectaudit.ro/tag/audit-intern">audit intern</a>
     <a href="https://corectaudit.ro/tag/financiar">Financiar</a>
      <a href="https://corectaudit.ro/tag/juridic">juridic</a> 
  </div> */}
        {/* </footer> */}
        <Modal show={this.state.modalIsOpen} closed={this.closeModal}/>
        {this.state.modalIsOpen ? <Backdrop show /> : null}
        
        
        <button id="modal_cerere_btn" onClick={this.showModal}>
          Cere o oferta chiar acum
        </button>

        <p />

      
      </article>
      <div id="secondary" className="form-conta" role="complementary">
        <div className="well">
          <aside id="text-4" className="widget widget_text"><h3 className="widget-title">Cerere Oferta</h3>		
            <div className="container2">
              <form name="contactForm" method="post">
                <label htmlFor="nume_soc">Numele societatii</label>
                <input type="text" id="nume_soc" name="numesoc" placeholder="Numele societatii.." />
                <div className="error" id="numesocErr" />
                <label htmlFor="nr_sal">Numarul de salariati</label>
                <br></br>
                <input type="number" id="nr_sal" name="numar" placeholder="Numarul de salariati.." />
                <br /><br />
                <label htmlFor="nume">Nume</label>    
                <input type="text" id="nume" name="nume_" placeholder="Numele tau.." />
                <div className="error" id="numeErr" />
                <label htmlFor="pren">Prenume</label>
                <input type="text" id="pren" name="pren_" placeholder="Prenumele tau.." />
                <div className="error" id="prenErr" />
                <label htmlFor="email">Email-ul tau prin care vom comunica:</label>
                <input type="email" id="email" name="email_" placeholder="Ex.office@corectaudit.ro" />
                <div className="error" id="emailErr" />
                <label htmlFor="tel">Numarul tau de telefon</label>
                <br></br>
                <input type="number" id="tel" name="tel__" placeholder="Telefonul tau..." pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required />
                <div className="error" id="telErr" />
                <br /><br />
                <label htmlFor="subject"> Cu ce putem ajuta?  <strong>(optional)</strong></label>
                <textarea id="subject" name="subject" placeholder="Scrie aici daca vrei sa ne dai si alte detalii..." style={{height: 200, fontStyle: 'italic'}} defaultValue={""} />
                <input type="checkbox" name="checkbox" defaultValue="check" id="agree" /> Am citit si sunt de acord cu Termenii si <a href="/#pol" target="_blank"  rel="noreferrer" id="popuptrigger" onClick={this.myFunction} style={{backgroundColor: 'rgb(95, 95, 255)'}}> Politica de confidentialitate</a>
                <p>
                  <input type="button" onClick={this.validateForm} id="submitted" defaultValue="TRIMITE SOLICITAREA" /><ToastContainer/>
                </p>
              </form>
            </div>
            <div className="modal1 animate" id="popup">
            <Helmet>
          <script src="https://smtpjs.com/v3/smtp.js" type="text/javascript" />
          </Helmet>
              <div className="modal-content" id="boss">
                <span id="close-button" onClick={this.closePopUp}>×</span>
                <h4 style={{textIndent: 0, fontFamily: 'Cambria, Cochin, Georgia, Times, "Times New Roman", serif'}}>Cererea ta va fi trimisa cu urmatoarele date: </h4>
                <textarea id="populate" style={{width: '80%', height: '70%'}} readOnly defaultValue={"        "} />
                <form>
                  <input type="button" id="submit-email" onClick={this.sendEmail} defaultValue="FINALIZEAZA SOLICITAREA" />
                </form>
              </div>
            </div>
          </aside>
     

        	</div>
      </div>
    
    </section>
 
   
    <a href="#top" id="back-to-top" onClick={this.topFunction} onScroll={this.scrollFunction}><i className="fa fa-angle-up" /></a>
    
  </>
  
  );}


}


export default Contabilitate;










