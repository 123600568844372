import React, { Component } from 'react';
import './style/design_audit.css';
import './style/design.css';
import Modal from '../Modal';
import Backdrop from './Backdrop';
import 'react-toastify/dist/ReactToastify.css';
 import {Helmet} from 'react-helmet';
 import { ToastContainer, toast } from 'react-toastify';

 import 'react-toastify/dist/ReactToastify.css';





 
const customId = "custom-id-yes";
const customIdd = "custom-id-yess";
const customIddd = "custom-id-yesss";
const notify = () => toast.error("Verificati toate campurile inainte de a continua!", {
  toastId: customId,
  position: toast.POSITION.TOP_CENTER
});

const notifyy = () => toast.warn("Va rugam sa confirmati ca ati citit Termenii si Politica de confidentialitate!", {
    toastId: customIdd,
    delay:2000,
    position: toast.POSITION.TOP_CENTER
  });
  const notifyyy = () => toast.success("Cererea dumneavoastra a fost inregistrata cu succes!", {
    toastId: customIddd,
    position: toast.POSITION.TOP_CENTER
    
  });
  var printError = (elemId,hintMsg) =>{
    document.getElementById(elemId).innerHTML = hintMsg;
};
let  { numeErr, prenErr , emailErr, telErr} = true;

class Analiza extends Component{
 
    state = {
      modalIsOpen: false,
      showBlock: false
    };
  
    showModal = () => {
      this.setState({ modalIsOpen: true });
    };
  
    closeModal = () => {
      this.setState({ modalIsOpen: false });
    };
  
    
   
    validateForm() {
        
      var numesoc = document.contactForm.nume_soc.value;
      var nume = document.contactForm.nume.value;
      var pren_ = document.contactForm.pren.value;
      var email_ = document.contactForm.email.value;
      var tel = document.contactForm.tel.value;
      let checkbox = false;
      var nr_sal= document.getElementById("nr_sal").value;
      var subject=document.getElementById("subject").value;
      
    
      
      var  numesocErr  = numeErr = prenErr = emailErr= telErr=true;
      
      //Validate numesoc
      if(numesoc === "") {
          
          printError("numesocErr", "Completeaza numele societatii");
      } else {
          var regex = /^[0-9a-zA-Z\s]+$/;                
          if(regex.test(numesoc) === false) {
              printError("numesocErr", "Completeaza cu un nume valid");
          } else {
              printError("numesocErr", "");
             numesocErr = false;
          }
      }
  
      //Validate pren
      if(pren_ === "") {
          printError("prenErr", "Completeaza prenumele");
      } else {
           regex = /^[a-zA-Z\s]+$/;                
          if(regex.test(pren_) === false) {
              printError("prenErr", "Completeaza cu un prenume valid");
          } else {
              printError("prenErr", "");
             prenErr = false;
          }
      }
      // Validate name
      if(nume === "") {
          printError("numeErr", "Completeaza numele");
      } else {
           regex = /^[a-zA-Z\s]+$/;                
          if(regex.test(nume) === false) {
              printError("numeErr", "Completeaza cu un nume valid");
          } else {
              printError("numeErr", "");
             numeErr = false;
          }
      }
      
      // Validate email address
      if(email_ === "") {
          printError("emailErr", "Completeaza email-ul");
      } else {
           regex = /^\S+@\S+\.\S+$/;
          if(regex.test(email_) === false) {
               printError("emailErr", "Completeaza cu o adresa de email valida");
             
               window.history.go(-1);
          } else{
              printError("emailErr", "");
              emailErr = false;
          }
      }
      
      if(tel === "") {
          printError("telErr", "Completeaza telefonul tau");
      } else {
          regex = /^[0-9]+$/;                
          if(regex.test(tel) === false ){//|| tel[0] !== '0' || tel[1] !== '7' || tel.length !== 10) {
              printError("telErr", "Completeaza cu un numar de telefon valid");
          } else{
              if(tel[0] !== '0' || tel[1] !== '7'){
                  printError("telErr", "Numarul trebuie sa inceapa cu '07'");
              }else{
                  if(tel.length !== 10){
                      printError("telErr","Numarul trebuie sa fie format din 10 cifre, nu din " + tel.length + " cifre");
                  }else{
                      printError("telErr", "");
                     telErr = false;
                    
                  }
              } 
          }
      }
  
      if(document.getElementById('agree').checked){
          checkbox = true;
       }else { 
          //  alert('Va rugam sa confirmati ca ati citit Termenii si Politica de confidentialitate!'); 
          notifyy();
      }
      
  
      
      if((numesocErr || numeErr || prenErr || emailErr || telErr) === false && checkbox === true) {
       
          if( document.getElementById("popup").style.display="block"){
        
              var dataPreview = 
           
              "Numele societatii: \n" + numesoc + "\n\n" +
               "Nr. salariati:  \n" + nr_sal + "\n\n" +
              "Numele tau: \n" + nume + "\n\n" +
              "Prenumele tau: \n" + pren_ + "\n\n" +
              "Email-ul tau: \n" + email_ + "\n\n" +
              "Telefonul tau: \n" + tel + "\n\n"+
              "Mesajul tau: \n" + subject +"\n\n\n";
  
          var popup_text= document.getElementById("populate");
          popup_text.value = dataPreview;
          
          }
          else{
              alert("Nu se poate");
          }
       } else {
          //  alert("Verificati toate campurile inainte de a continua!");
      
         notify();
     
       }
      };
      
    
      
      
      sendEmail() {
          // let Email = { send: function (a) { return new Promise(function (n, e) { a.nocache = Math.floor(1e6 * Math.random() + 1), a.Action = "Send"; var t = JSON.stringify(a); Email.ajaxPost("https://smtpjs.com/v3/smtpjs.aspx?", t, function (e) { n(e) }) }) }, ajaxPost: function (e, n, t) { var a = Email.createCORSRequest("POST", e); a.setRequestHeader("Content-type", "application/x-www-form-urlencoded"), a.onload = function () { var e = a.responseText; null != t && t(e) }, a.send(n) }, ajax: function (e, n) { var t = Email.createCORSRequest("GET", e); t.onload = function () { var e = t.responseText; null != n && n(e) }, t.send() }, createCORSRequest: function (e, n) { var t = new XMLHttpRequest; return "withCredentials" in t ? t.open(e, n, !0) : "undefined" != typeof XDomainRequest ? (t = new XDomainRequest).open(e, n) : t = null, t } };
          var numesoc = document.contactForm.numesoc.value;
          var nume = document.contactForm.nume_.value;
          var pren_ = document.contactForm.pren_.value;
          var email_ = document.contactForm.email_.value;
          var tel = document.contactForm.tel.value;
          var nr_sal= document.getElementById("nr_sal").value;
          var subject=document.getElementById("subject").value;
          
        
          var dataPreview = 
      
          "Numele societatii: \n" + numesoc + "\n\n" +
           "Nr. salariati:  \n" + nr_sal + "\n\n" +
          "Numele tau: \n" + nume + "\n\n" +
          "Prenumele tau: \n" + pren_ + "\n\n" +
          "Email-ul tau: \n" + email_ + "\n\n" +
          "Telefonul tau: \n" + tel + "\n\n"+
          "Mesajul tau: \n" + subject +"\n\n\n";
        window.Email.send({
              SecureToken: "1c30e605-e052-4676-8d2e-61bdab667885",
              To:'mariaflavia8288@gmail.com, cristina.ciubuc@corectaudit.ro',
              From: "fonceala@gmail.com",
              Subject: "no-reply",
              Body: "Cererea dumneavoastra a fost inregistrata!"+ "\n\n\n\n" + dataPreview,
          }).then(function (message) { 
              // alert("Cererea dumneavoastra a fost inregistrata cu succes!");
               notifyyy();
            
          //   window.history.go(-1);
              
            });
      }
  
  
    render(){
    return (

        <>
        <div id="includedContentAnaliza">
          <article id="despre-analiza">
            <h2 id="titlu_analiza"> <em style={{color: 'violet'}}>Analiza financiara</em>  Corect Audit Consulting SRL</h2>
            <p id="paragraf_mare">
            <p id="para_analiza1"> Analiza financiara presupune <strong> analiza cash flow-ului, a planului de afaceri si a bilantului prescurtat</strong> pentru a va ajuta la identificarea activitatii nesatisfacatoare a societatii si la stabilirea masurilor care vor infuenta rezultatele viitoare.</p>
            <p id="para_analiza2"> Analiza financiara contribuie la cunoasterea continua a eficientei utilizarii resureselor umane, materiale si financiare pentru atingerea <strong>obiectivului principal – maximizarea profitului sau in vederea obtinerii de finantari.</strong></p>
            <p />
            <p id="para_analiza3"><strong> Consultanta financiara cuprinde :</strong></p>
            <ul id="lista_analiza">
              <li>analiza pozitiei financiare a companiei;</li>
              <li>analiza si componenta financiara a contului de rezultate;</li>
              <li>intocmirea bugetului de venituri si cheltuieli;</li>
              <li>analiza fluxurilor de trezorerie; elaborarea si analiza cash-flow-ului, a fondului de rulment si a necesarului de fond de rulment;</li>
              <li>analiza corelata a creantelor si datoriilor;</li>        
              <li>analiza capitalurilor si imobilizarilor;</li>
              <li>elaborarea documentatiei necesare in vederea obtinerii diferitelor tipuri de finantari: leasing, factoring, linii de credit, credite de investitii.
              </li>
            </ul>
            </p>
     
            
            
            <Modal show={this.state.modalIsOpen} closed={this.closeModal}/>
        {this.state.modalIsOpen ? <Backdrop show /> : null}
        
        
        <button id="modal_cerere_btn" onClick={this.showModal}>
          Cere o oferta chiar acum
        </button>


            <p />
          </article>
          <div id="secondary" className="widget-area col-sm-12 col-md-4" role="complementary">
            <div className="well_analiza">
              <aside id="text-4" className="widget widget_text"><h3 className="widget-title">Cerere Oferta</h3>		
                <div className="container2">
                  <form name="contactForm" method="post">
                    <label htmlFor="nume_soc">Numele societatii</label>
                    <input type="text" id="nume_soc" name="numesoc" placeholder="Numele societatii.." />
                    <div className="error" id="numesocErr" />
                    <label htmlFor="nr_sal">Numarul de salariati</label>
                    <br></br>
                    <input type="number" id="nr_sal" name="numar" placeholder="Numarul de salariati.." />
                    <br /><br />
                    <label htmlFor="nume">Nume</label>    
                    <input type="text" id="nume" name="nume_" placeholder="Numele tau.." />
                    <div className="error" id="numeErr" />
                    <label htmlFor="pren">Prenume</label>
                    <input type="text" id="pren" name="pren_" placeholder="Prenumele tau.." />
                    <div className="error" id="prenErr" />
                    <label htmlFor="email">Email-ul tau prin care vom comunica:</label>
                    <input type="email" id="email" name="email_" placeholder="Ex.office@corectaudit.ro" />
                    <div className="error" id="emailErr" />
                    <label htmlFor="tel">Numarul tau de telefon</label>
                    <input type="tel" id="tel" name="tel__" placeholder="Telefonul tau..." pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required />
                    <div className="error" id="telErr" />
                    <br /><br />
                    <label htmlFor="subject"> Cu ce putem ajuta?  <strong>(optional)</strong></label>
                    <textarea id="subject" name="subject" placeholder="Scrie aici daca vrei sa ne dai si alte detalii..." style={{height: 200, fontStyle: 'italic'}} defaultValue={""} />
                    <input type="checkbox" name="checkbox" defaultValue="check" id="agree" /> Am citit si sunt de acord cu Termenii si <a href="/#pol" target="_blank" rel="noreferrer" id="popuptrigger" onClick={this.myFunction} style={{backgroundColor: 'rgb(95, 95, 255)'}}> Politica de confidentialitate</a>
                    <p>
                      <input type="button" onClick={this.validateForm} id="submitted" defaultValue="TRIMITE SOLICITAREA" /><ToastContainer/>
                    </p>
                  </form>
                </div>
                <div className="modal1 animate" id="popup">
                <Helmet>
          <script src="https://smtpjs.com/v3/smtp.js" type="text/javascript" />
          </Helmet>
                  <div className="modal-content" id="boss">
                    <span id="close-button" onClick={this.closePopUp}>×</span>
                    <h4 style={{textIndent: 0, fontFamily: 'Cambria, Cochin, Georgia, Times, "Times New Roman", serif'}}>Cererea ta va fi trimisa cu urmatoarele date: </h4>
                    <textarea id="populate" style={{width: '80%', height: '70%'}} readOnly defaultValue={"        "} />
                    <form>
                      <input type="button" id="submit-email" onClick={this.sendEmail} defaultValue="FINALIZEAZA SOLICITAREA" />
                    </form>
                  </div>
                </div>
              {/* </aside><aside id="text-6" className="widget widget_text"><h3 className="widget-title">Inscriere newsletter</h3>			<div className="textwidget"><p>Cu ajutorul Newsletter-ului ai acces la ofertele si la informatiile actuale din domeniu</p> */}
                  {/* <style type="text/css" dangerouslySetInnerHTML={{__html: "\n  #mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }\n\n" }} /> */}
                  {/* <div id="mc_embed_signup"> */}
                    {/* <form name="newsForm" method="post">
                      <div id="mc_embed_signup_scroll">
                        <div className="mc-field-group">
                          <label htmlFor="EMAIL">Adresa de email  <span className="asterisk">*</span>
                          </label>
                          <input type="email" defaultValue name="EMAIL" className="required email" id="EMAIL_" aria-required="true" />
                        </div>
                        <div className="mc-field-group">
                          <label htmlFor="NUME">Nume </label>
                          <input type="text" defaultValue name="NUME" className id="NUME" />
                        </div>
                        <div className="mc-field-group">
                          <label htmlFor="NUMEC">Companie </label>
                          <input type="text" defaultValue name="NUMEC" className id="NUMEC_" />
                        </div>
                        <div id="mce-responses" className="clear">
                          <div className="response" id="mce-error-response" style={{display: 'none'}} />
                          <div className="response" id="mce-success-response" style={{display: 'none'}} />
                        </div> 
                        <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_1b4898a980bc63d35a508ab87_0cfc8d4b56" tabIndex={-1} defaultValue wtx-context="04DEFCEA-9AEA-4A6D-84C6-D1E502AD2C54" /></div>
                        <div className="clear"><input type="button" onclick="getData()" defaultValue="Abonare" name="subscribe" id="mc-embedded-subscribe" className="button btn btn-default" wtx-context="66CA611B-7C4D-44B5-99DF-67D1DA56F244" /></div>
                      </div> */}
                    {/* </form> */}
                  {/* </div> */}
                {/* </div> */}
              </aside>		
              </div>
          </div>
        </div>
        {/* <section className="footer-contab">
          <div className="footer-elements-contab">
            <div className="row">
              <div className="col-sm-12">
                <div className="row no-gutter">
                  <span />
                  <div className="col-sm-3">
                    <div className="footer-element">
                      <span className="typcn typcn-watch" />
                      <p>LUNI-SAMBATA<span>08:00 - 18:00</span></p>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="footer-element">
                      <span className="typcn typcn-location-outline" />
                      <p>Strada Balsamului<span>Bucurestii-Noi, sector 1, Bucuresti</span></p>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="footer-element">
                      <span className="typcn typcn-mail" />
                      <p>E-MAIL:<span><a href>OFFICE@CORECTAUDIT.RO</a></span></p>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="footer-element">
                      <span className="typcn typcn-phone-outline" />
                      <p>SOLICITA UN APEL:<span><a href="tel:(031)102 83 69">(031)102 83 69</a></span></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-widgets">
            <div className="row">
              <div className="col-sm-33">
                <div className="footer-widget-contab">
                  <img id="footerImg" src={CImg} alt style={{opacity: '0.9'}} max-width=" 100%" height="60px"  display=" inline-block" vertical-align=" middle" />
                  <p>Ne puteti gasi si pe social media.
                  </p>
                  <ul className="social-links" style={{textIndent: 0}}>
                    <li><a href="https://www.facebook.com/corectauditro" target="_blank" title="Facebook"><i className="fa fa-facebook" /></a></li>
                    <li><a href="https://www.instagram.com/corectauditro/" title="Instagram"><i className="fa fa-instagram" /></a></li>
                  </ul>
                  <br /><br />
                  <div id="politica">
                    
                    <button id="pol">Protectia datelor</button>
                  </div> 
                </div>
              </div>
              <div className="col-sm-333">
                <div className="footer-widget-contab">
                  <h5>FII LA CURENT CU NOUTATILE</h5>
                  <ul className="posts" style={{textIndent: 0}}>
                    <li style={{whiteSpace: 'nowrap', overflow: 'hidden',  textOverflow: 'ellipsis'}}>
                    <a href="https://www.cafr.ro/perioada-de-inscriere-a-firmelor-in-schema-de-ajutor-horeca-va-fi-prelungita-cu-o-saptamana-pana-la-26-iulie-2021/"> Perioada de înscriere a firmelor în schema de ajutor HoReCa va fi prelungită cu o săptămână,
până la 26 iulie 2021  </a>
      <span>acum 2 luni</span>
      </li>
      <li style={{whiteSpace: 'nowrap', overflow: 'hidden',  textOverflow: 'ellipsis'}}><a href="https://www.anaf.ro/restante/">ANAF va publica în această lună lista cu datornicii care nu şi-au plătit obligaţiile fiscale în T2 2021</a>
      <span>acum 2 luni</span>
      </li>
      <li style={{whiteSpace: 'nowrap', overflow: 'hidden',  textOverflow: 'ellipsis'}}>
        <a href="https://static.anaf.ro/static/10/Anaf/AsistentaContribuabili_r/Ghid_profesii_liberale_05022021.pdf">Ghidul fiscal al contribuabililor care realizează venituri din profesii liberale a fost publicat pe site-ul ANAF </a>
        <span>acum 4 luni</span>
        </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-33" style={{maxWidth: 'fit-content'}}>
                <div className="footer-widget-contab">
                  <h5>LINK-URI UTILE</h5>
                  <ul className="posts" style={{textIndent: 0, width: 100}}>
                  <li width="20%" id="linkss"><a href="/#menu-section">ACASA</a></li>
      <li><a href="/#servicii">SERVICII</a></li>
      <li><a href="/#text_despre">DESPRE NOI</a></li>
      <li><a href="/#pol">CONTACT</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-3" style={{maxWidth: 'fit-content'}}>
                <div className="footer-widget-contab">
                  <div ><iframe id="mapa" frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0} src="https://maps.google.com/maps?width=100%25&height=600&hl=en&q=Strada%20Balsamului,%20Bucuresti+(Corect%20Audit%20Consulting%20S.R.L)&t=&z=17&ie=UTF8&iwloc=B&output=embed" /></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="copyright">
          <div className="row">
            <div className="col-sm-12">
              <p className="text-center">Copyright Corect Audit S.R.L. 2021. Toate drepturile rezervate.</p>
            </div>
          </div>
        </div> */}
        <a href="#top" id="back-to-top" onClick={this.topFunction} onScroll={this.scrollFunction}><i className="fa fa-angle-up" /></a>
      </>


        );}


}


export default Analiza;