import React from 'react';
import { ToastContainer } from 'react-toastify';


import CSSTransition from "react-transition-group/CSSTransition";

import './Modal.css';

import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



var printError = (elemId,hintMsg) =>{
    document.getElementById(elemId).innerHTML = hintMsg;
};
let  {numesocErr, numeErr, prenErr , emailErr, telErr} = true;
var clsBtn= document.getElementById('close-button');
function closePopUp(){
    document.getElementById("popup").style.display = "none";
}
//  var clsBtn=document.addEventListener("click",closePopUp);
const addPop= document.getElementById('add-modal');
const container= document.getElementById('.container');
function open_pop(){
    addPop.innerHTML= container;
    addPop.classList.toggle('show');

}

var dataPreview={};


const animationTiming = {
    enter: 400,
    exit: 1000
};

const customId = "custom-id-yes";
const customIdd = "custom-id-yess";
const customIddd = "custom-id-yesss";
const notify = () => toast.error("Verificati toate campurile inainte de a continua!", {
  toastId: customId
});

const notifyy = () => toast.warn("Va rugam sa confirmati ca ati citit Termenii si Politica de confidentialitate!", {
    toastId: customIdd,
    delay:2000
  });
  const notifyyy = () => toast.success("Cererea dumneavoastra a fost inregistrata cu succes!", {
    toastId: customIddd,
    position: "top-center"
    
  });

function validateForm() {
  
var numesoc = document.getElementById("nume_soc").value;;
var nume = document.getElementById("nume").value;
var pren_ = document.getElementById("pren").value;
var email_ =document.getElementById("email").value;
var tel = document.getElementById("tel").value;
let checkbox = false;
var nr_sal= document.getElementById("nr_sal").value;
var subject=document.getElementById("subject").value;
// var domeniu=document.getElementById("domeniu").value;
// var domenii = [];
// var checkboxes = document.getElementsByName("domeniu");


var  numesocErr  = numeErr = prenErr = emailErr= telErr=true;

//Validate numesoc
if(numesoc === "") {
  
  printError("numesocErr", "Completeaza numele societatii");
} else {
  var regex = /^[0-9a-zA-Z\s]+$/;                
  if(regex.test(numesoc) === false) {
      printError("numesocErr", "Completeaza cu un nume valid");
  } else {
      printError("numesocErr", "");
     numesocErr = false;
  }
}

//Validate pren
if(pren_ === "") {
  printError("prenErr", "Completeaza prenumele");
} else {
   regex = /^[a-zA-Z\s]+$/;                
  if(regex.test(pren_) === false) {
      printError("prenErr", "Completeaza cu un prenume valid");
  } else {
      printError("prenErr", "");
     prenErr = false;
  }
}
// Validate name
if(nume === "") {
  printError("numeErr", "Completeaza numele");
} else {
  regex = /^[a-zA-Z\s]+$/;                
  if(regex.test(nume) === false) {
      printError("numeErr", "Completeaza cu un nume valid");
  } else {
      printError("numeErr", "");
     numeErr = false;
  }
}

// Validate email address
if(email_ === "") {
  printError("emailErr", "Completeaza email-ul");
} else {
   regex = /^\S+@\S+\.\S+$/;
  if(regex.test(email_) === false) {
       printError("emailErr", "Completeaza cu o adresa de email valida");
     
       window.history.go(-1);
  } else{
      printError("emailErr", "");
      emailErr = false;
  }
}

if(tel === "") {
  printError("telErr", "Completeaza telefonul tau");
} else {
   regex = /^[0-9]+$/;                
  if(regex.test(tel) === false ){//|| tel[0] !== '0' || tel[1] !== '7' || tel.length !== 10) {
      printError("telErr", "Completeaza cu un numar de telefon valid");
  } else{
      if(tel[0] !== '0' || tel[1] !== '7'){
          printError("telErr", "Numarul trebuie sa inceapa cu '07'");
      }else{
          if(tel.length !== 10){
              printError("telErr","Numarul trebuie sa fie format din 10 cifre, nu din " + tel.length + " cifre");
          }else{
              printError("telErr", "");
             telErr = false;
            
          }
      } 
  }
}

if(document.getElementById('agree').checked){
  checkbox = true;
}else { 
  //  alert('Va rugam sa confirmati ca ati citit Termenii si Politica de confidentialitate!'); 
  notifyy();
}



if((numesocErr || numeErr || prenErr || emailErr || telErr) === false && checkbox === true) {

  if( document.getElementById("popup").style.display="block"){

      var dataPreview = 
      // "Domeniul de activitate: \n" + domeniu + "\n\n" +
      "Numele societatii: \n" + numesoc + "\n\n" +
       "Nr. salariati:  \n" + nr_sal + "\n\n" +
      "Numele tau: \n" + nume + "\n\n" +
      "Prenumele tau: \n" + pren_ + "\n\n" +
      "Email-ul tau: \n" + email_ + "\n\n" +
      "Telefonul tau: \n" + tel + "\n\n"+
      "Mesajul tau: \n" + subject +"\n\n\n";

  var popup_text= document.getElementById("populate");
  popup_text.value = dataPreview;
  
  }
  else{
      alert("Nu se poate");
  }
} else {
  //  alert("Verificati toate campurile inainte de a continua!");

 notify();

}
};




function sendEmail() {
  // let Email = { send: function (a) { return new Promise(function (n, e) { a.nocache = Math.floor(1e6 * Math.random() + 1), a.Action = "Send"; var t = JSON.stringify(a); Email.ajaxPost("https://smtpjs.com/v3/smtpjs.aspx?", t, function (e) { n(e) }) }) }, ajaxPost: function (e, n, t) { var a = Email.createCORSRequest("POST", e); a.setRequestHeader("Content-type", "application/x-www-form-urlencoded"), a.onload = function () { var e = a.responseText; null != t && t(e) }, a.send(n) }, ajax: function (e, n) { var t = Email.createCORSRequest("GET", e); t.onload = function () { var e = t.responseText; null != n && n(e) }, t.send() }, createCORSRequest: function (e, n) { var t = new XMLHttpRequest; return "withCredentials" in t ? t.open(e, n, !0) : "undefined" != typeof XDomainRequest ? (t = new XDomainRequest).open(e, n) : t = null, t } };
  var numesoc = document.getElementById("nume_soc").value;;
  var nume = document.getElementById("nume").value;
  var pren_ = document.getElementById("pren").value;
  var email_ =document.getElementById("email").value;
  var tel = document.getElementById("tel").value;
 
  var nr_sal= document.getElementById("nr_sal").value;
  var subject=document.getElementById("subject").value;
  let checkbox = false;
  var dataPreview = 

  "Numele societatii: \n" + numesoc + "\n\n" +
   "Nr. salariati:  \n" + nr_sal + "\n\n" +
  "Numele tau: \n" + nume + "\n\n" +
  "Prenumele tau: \n" + pren_ + "\n\n" +
  "Email-ul tau: \n" + email_ + "\n\n" +
  "Telefonul tau: \n" + tel + "\n\n"+
  "Mesajul tau: \n" + subject +"\n\n\n";
window.Email.send({
      SecureToken: "1c30e605-e052-4676-8d2e-61bdab667885",
      To:'mariaflavia8288@gmail.com, cristina.ciubuc@corectaudit.ro',
      From: "fonceala@gmail.com",
      Subject: "no-reply",
      Body: "Cererea dumneavoastra a fost inregistrata!"+ "" + dataPreview,
  }).then(function (message) { 
      // alert("Cererea dumneavoastra a fost inregistrata cu succes!");
       notifyyy();
    
  //   window.history.go(-1);
      
    });
}


const modal = props => {
  return (
    <CSSTransition 
        mountOnEnter 
        unmountOnExit 
        in={props.show} 
        timeout={animationTiming}
        classNames={{
            enter: '',
            enterActive: 'ModalOpen',
            exit: '',
            exitActive: 'ModalClosed'
        }}>
          <div className="Modal">
          <div className="well">
          <aside id="text-4" className="widget widget_text"><h3 className="widget-title">Cerere Oferta</h3>		
            <div className="container2">
              <form name="contactForm" method="post" style={{width:'270px'}}>
                <label htmlFor="nume_soc">Numele societatii</label>
                <input type="text" id="nume_soc" name="numesoc" placeholder="Numele societatii.." style={{height:'10px'}}/>
                <div className="error" id="numesocErr" />
                <label htmlFor="nr_sal">Numarul de salariati</label>
                <input type="number" id="nr_sal" name="numar" placeholder="Numarul de salariati.." style={{marginLeft:'48px',height:'25px', width:'160px', marginRight:'30px'}} />
                <br></br>
                <label htmlFor="nume">Nume</label>    
                <input type="text" id="nume" name="nume_" placeholder="Numele tau.." style={{height:'10px'}} />
                <div className="error" id="numeErr" />
                <label htmlFor="pren">Prenume</label>
                <input type="text" id="pren" name="pren_" placeholder="Prenumele tau.."style={{height:'10px'}} />
                <div className="error" id="prenErr" />
                <label htmlFor="email">Email-ul tau prin care vom comunica:</label>
                <input type="email" id="email" name="email_" placeholder="Ex.office@corectaudit.ro" style={{height:'10px'}}/>
                <div className="error" id="emailErr" />
                <label htmlFor="tel">Numarul tau de telefon</label>
                <input type="tel" id="tel" name="tel__" placeholder="Telefon..." pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required  style={{height:'25px', width:'180px'}}/>
                <div className="error" id="telErr" />
              
                <label htmlFor="subject"> Cu ce putem ajuta?  <strong>(optional)</strong></label>
                <textarea id="subject" name="subject" placeholder="Scrie aici daca vrei sa ne dai si alte detalii..."  defaultValue={""} />
                <input type="checkbox" name="checkbox" defaultValue="check" id="agree" style={{height:'10px'}}/> Am citit si sunt de acord cu Termenii si <a href="index.html#pol" target="_blank" id="popuptrigger" onclick="myFunction()" style={{backgroundColor: 'rgb(95, 95, 255)'}}> Politica de confidentialitate</a>
                <p>
                  <input type="button" onClick={validateForm} id="submittedd" defaultValue="TRIMITE SOLICITAREA" /><ToastContainer/>
                </p>
              </form>
            </div>
            <div className="modal1 animate" id="popup">
              <div className="modal-content" id="boss">
              <span id="close-button" onClick={closePopUp}>×</span>
                <h4 style={{textIndent: 0, fontFamily: 'Cambria, Cochin, Georgia, Times, "Times New Roman", serif'}}>Cererea ta va fi trimisa cu urmatoarele date: </h4>
                <textarea id="populate" style={{width: '80%', height: '70%'}} readOnly defaultValue={"        "} />
                <form>
                  <input type="button" id="submit-email" onClick={sendEmail} defaultValue="FINALIZEAZA SOLICITAREA" />
                </form>
              </div>
            </div>
          </aside>
          </div>
            <button id="inchide_cerere" onClick={props.closed}>
              Nu acum
            </button>
          </div>
    </CSSTransition>
  );
};

export default modal;
