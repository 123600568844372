import React from 'react';
import './Footer.css';
import { toast } from 'react-toastify';
import Corect from './images/1ii.jpg';
import 'react-toastify/dist/ReactToastify.css';
import {Link} from 'react-router-dom';
import './style/style.scss';
import Politica from './Politica';
import Backdrop from './Backdrop';


// function date(){ //   document.write(new Date().getFullYear());}

  var    newsletterData;
const sendNewsletter=(text) =>{
  window.Email.send({
       SecureToken: "1c30e605-e052-4676-8d2e-61bdab667885",
       To:'mariaflavia8288@gmail.com, cristina.ciubuc@corectaudit.ro',
       From: "fonceala@gmail.com",
       Subject: "Newsletter",
       Body: "Utilizatorul s-a inscris la newsletter cu email-ul: "+ "" + text,
   }).then(function (message) { 
       notifyy();
       // window.history.go(-1);
     });
   
};

const customId = "custom-id-yes";
const customIdd = "custom-id-yess";
const customIddd = "custom-id-yesss";
const noteId = "cumstom-id-note";

const Europene = () => toast.info(
  <div>
    <Link to="/fonduri">Consultanta Fonduri Europene! Click chiar aici pentru informatii!</Link>
  </div>,{toastId: noteId,  position: toast.POSITION.TOP_CENTER}
);


const notify = () => toast.warn("Link-ul a fost copiat în clipboard!", {
  toastId: customId
});

const notifyy = () => toast.success("Te-ai abonat la Newsletter-ul Corect Audit! ", {
  toastId: customIdd
});

const notifye = () => toast.error("Introduceti un e-mail valid! ", {
  toastId: customIddd
});

class Footer extends React.Component {

    state = {
        modalIsOpen: false,
        showBlock: false,
        listItemsIsOpen: false,
        currentItem: -1,
        loading: false,
        items: [],
        modalOpen: false
}



 showModal = () => {
    this.setState({ modalIsOpen: true });
  };

 closeModal = () => {
    this.setState({ modalIsOpen: false });
  };
 
  


  handleModalOpen = () => {
    this.setState((prevState) => {
       return{
          modalOpen: !prevState.modalOpen
       }
    })
 };

 

 openModal=() => {
   this.setState({modalIsOpen: true});
 };
 getData(){
    
  var email = document.getElementById('EMAIL_').value;

  newsletterData =
  "\n Email-ul oferit pentru newsletter: " + email;

  if(email !== "" && email.includes('@')){
      sendNewsletter(newsletterData);
  }else{
     notifye();
  }

  document.getElementById('EMAIL_').value='';

};
    render(){
        return(
        <footer id="footerElem">
    
    <section className="footer">
      <div className="footer-elements" >
      <div className="row">
      <div className="col-sm-12">
      <div className="row no-gutter">
      <span></span>
      <div className="col-sm-3">
      <div className="footer-element">
      <span className="el typcn-watch"></span>
      <p>LUNI-VINERI<span>09:00 - 18:00</span></p>
      </div>
      </div>
      
      <div className="col-sm-3">
        <div className="footer-element">
        <span className="typcn typcn-location-outline"></span>
        <p><span>Bucurestii-Noi, sector 1, Bucuresti</span></p>
        </div>
        </div>
    
      
      <div className="col-sm-3">
      <div className="footer-element">
      <span className="el"></span>
      <p>E-MAIL:<span><a href="mailto:office@corectaudit.ro">OFFICE@CORECTAUDIT.RO</a></span></p>
      </div>
      </div>

      
      <div className="col-sm-3">
      <div className="footer-element">
      <span className="el"></span>
      <p>SOLICITA UN APEL:<span><a href="tel:(031)102 83 69">(031)102 83 69</a></span></p>
      </div>
      </div>
      
      </div>
      </div>
      </div>
      
      </div>
      <div className="footer-widgets">
      <div className="widget-row">
      
      <div className="widget-col-sm-3">
      <div className="footer-widget">
         <img id="footerImg" src={Corect} alt="footerimg" style={{opacity: '0.9', maxWidth:'100%', height: '60px', msinterpolationMode: 'bicubic', display: 'inline-block', verticalAlign: 'middle' }} />
      <p>Ne puteți găsi și pe social media.
        
      </p>
    
      <ul className="social-linkss" style={{textIndent:'0px'}}>
      <li><a href="https://www.facebook.com/corectauditro" title="Facebook" target="_blank" rel="noreferrer"><i className="fa fa-facebook"></i></a></li>
      <li><a href="https://www.instagram.com/corectauditro/" title="Instagram"><i className="fa fa-instagram"></i></a></li>
      </ul>
      <br></br>
      <br></br>
     
      <br></br>
        
        
       <hr></hr>
      <div id="politica">
      <Politica show={this.state.modalIsOpen} closed={this.closeModal}/>
        {this.state.modalIsOpen ? <Backdrop show /> : null}
        <button id="pol" onClick={this.showModal}>Protectia datelor</button>
      </div>
       </div>
      </div>
      
      
      <div className="widget-col-sm-3">
      <div className="footer-widget">
      <h5>FII LA CURENT CU NOUTATILE</h5>
      <ul className="posts" >
      
      <li style={{whiteSpace: 'nowrap', overflow: 'hidden',  textOverflow: 'ellipsis'}}>
        <a href="https://static.anaf.ro/static/3/Anaf/20211117135718_1627_com_chat.pdf">CHAT - un nou serviciu electronic al ANAF</a>
        <span>17 Noiembrie 2021 </span>
        </li>
    
      <li style={{whiteSpace: 'nowrap', overflow: 'hidden',  textOverflow: 'ellipsis'}}>
      <a href="https://static.anaf.ro/static/3/Anaf/20211021154500_1487-%2021.10.2021-%20%20comunicat.pdf">  NU DESCHIDETI MESAJELE FALSE, transmise în numele ANAF   </a>
      <span>21 Octombrie 2021</span>
      </li>
      <li style={{whiteSpace: 'nowrap', overflow: 'hidden',  textOverflow: 'ellipsis'}}><a href="https://static.anaf.ro/static/3/Anaf/20211021144145_1514-21.10.2021-cp%20verificari%20mari%20contribuabili%20amef.pdf">ANAF a demarat acțiuni de control la marii contribuabili

</a>
      <span> 21 Octombrie 2021</span>
      </li>
   
    
      </ul>
      </div>
      </div>
      
      
      <div className="widget-col-sm-3" style={{maxWidth:'fit-content'}}>
      <div className="footer-widget">
      <h5>LINK-URI UTILE</h5>
      <ul className="posts">
      <li width="20%" id="linkss"><a href="#menu-section">ACASA</a></li>
      <li><a href="#servicii">SERVICII</a></li>
      <li><a href="#despre_noi">DESPRE NOI</a></li>
      <li><a href="#pol">CONTACT</a></li>
      </ul>
      </div>
      </div>

      
      <div className="widget-col-sm-3">
      <div className="footer-widget">
      <h5>NEWSLETTER</h5>
      <p>Lasa aici email-ul tau ca sa primesti cele mai noi informatii de la noi!</p>
      <div id="mc_embed_signup">
      
      <input  type="email" name="EMAIL" id="EMAIL_" placeholder="E-mail" required />
      <div style={{position: 'absolute', left: '-5000px'}}>
      <input type="text" name="b_b5638e105dac814ad84960d90_9345afa0aa" tabIndex="-1" value="" wtx-context="276923B7-2C7A-4AB0-901D-7FCD90DFCF59"/>
      </div>
      <input type="submit" value="SUBSCRIBE" name="subscribe" onClick ={this.getData} id="mc-embedded-subscribe" className="mtr-btn button-blue" wtx-context="411D464B-79FC-4061-814C-1CBEED20C88B"/>
      
     
      </div>
      </div>
      </div>
      </div>
      </div>
      
</section>
      <div className="copyright">
        <div className="row">
        <div className="copy-col-sm-12">
        <p className="copy-text-center">&copy;Copyright Corect Audit S.R.L 2021.Toate drepturile rezervate.</p>
        
        </div>
        </div>
        </div>
        <a href="#top"id="back-to-top" onClick={this.topFunction} onScroll={this.scrollFunction}><i className="fa fa-angle-up" ></i></a>
        
  </footer> 
        );
    }
}

export default Footer;