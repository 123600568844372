import React from 'react';
import './style/design.css';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import ShareIcon from '@material-ui/icons/Share';
import Card from '@material-ui/core/Card';
 import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import {Popover, OverlayTrigger, CardImg} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import Conta from './images/conta.jpeg';
import Audit from './images/audit.jpeg';
import Analiza from './images/analizaf.jpeg';
import Cons from './images/cons.jpeg';
import Sal from './images/salaz.jpeg';
import 'react-toastify/dist/ReactToastify.css';
import Human from './images/human2.png';
import Anunt from './images/anunt.png';
import Titlu from './images/T5.png';
import mobilv from './images/mobil.mp4';
import CoverImg from './images/cover_t.png';
import {Link} from 'react-router-dom';
import './style/style.scss';
import Marquee from "react-fast-marquee";
import PDF from './documents/RelansareMasura3.pdf';

import {NewsTicker} from "react-announcement-ticker";

import $ from 'jquery'; 


import Despre from './Despre';
import anuntnews from './images/copy_477224245.png';
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  RedditIcon,
  TwitterIcon,
  WhatsappIcon

} from "react-share";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { CardHeader } from '@material-ui/core';
import AOS from'aos';
import "aos/dist/aos.css";
import {shareTextToWhatsApp} from 'share-text-to-whatsapp';



AOS.init({
    delay: 200, // values from 0 to 3000, with step 50ms
    duration: 1500, // values from 0 to 3000, with step 50ms
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
  });

  function sendM(){
  const data = {
    message: `
    
    Potrivit unui comunicat al Ministerului Finanțelor ,contribuabilii, care aveau datorii la buget în 31 martie 2020, pot cere anularea dobânzilor și a penalităților de întârziere aferente obligațiilor fiscale principale până la 31 martie 2021, inclusiv, termen care a fost prelungit prin OUG nr.226/2020.
    
    Sursă: ANAF
    Ți-a plăcut acest articol? Dă 𝑳𝒊𝒌𝒆, 𝑷𝒓𝒊𝒏𝒕𝒆𝒂𝒛𝒂̆ sau trimite pe 𝙀-𝙢𝙖𝙞𝙡!' `,
    // required
    title: '𝐌𝐢𝐧𝐢𝐬𝐭𝐞𝐫𝐮𝐥 𝐅𝐢𝐧𝐚𝐧𝐭̦𝐞𝐥𝐨𝐫 𝐚 𝐝𝐞𝐜𝐢𝐬 𝐦𝐞𝐧𝐭̦𝐢𝐧𝐞𝐫𝐞𝐚 𝐮𝐧𝐨𝐫 𝐟𝐚𝐜𝐢𝐥𝐢𝐭𝐚̆𝐭̦𝐢 𝐟𝐢𝐬𝐜𝐚𝐥𝐞 𝐚𝐯𝐚𝐧𝐭𝐚𝐣𝐨𝐚𝐬𝐞', // optional parameter
    url: 'https://corectaudit.ro', // optional parameter
  };
   
 shareTextToWhatsApp(data);
  }
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};





const customId = "custom-id-yes";
const customIdd = "custom-id-yess";
const customIddd = "custom-id-yesss";
const noteId = "cumstom-id-note";

const Europene = () => toast.info(
  <div>
    <Link to="/fonduri">Consultanta Fonduri Europene! Click chiar aici pentru informatii!</Link>
  </div>,{toastId: noteId,  position: toast.POSITION.TOP_CENTER}
);


const notify = () => toast.warn("Link-ul a fost copiat în clipboard!", {
  toastId: customId
});

const notifyy = () => toast.success("Te-ai abonat la Newsletter-ul Corect Audit! ", {
  toastId: customIdd
});

const notifye = () => toast.error("Introduceti un e-mail valid! ", {
  toastId: customIddd
});

// const note = () => toast.info(Europene,{
//     toastId: noteId,


// });


var mob= document.getElementById('mobil');
if ($(window).width() < 770) {
Europene();

}

// function showTime(){
//     var time = new Date();
//     var today = new Date();

//     var date = + today.getDate() + " " + today.toLocaleString('ro-Ro', { month: 'long' }) +" " + today.getFullYear();
    
//     var timed = time.getHours() + ":" + time.getMinutes() + ":" + time.getSeconds();
    
//     var dateTime = date +' '+ timed;

//     document.getElementById("myclock").innerText= dateTime;
//     document.getElementById("myclock").textContent= dateTime;
    
//     setTimeout(showTime,1000);

// }
// showTime();




var    newsletterData;
const sendNewsletter=(text) =>{
  window.Email.send({
       SecureToken: "1c30e605-e052-4676-8d2e-61bdab667885",
       To:'mariaflavia8288@gmail.com, cristina.ciubuc@corectaudit.ro',
       From: "fonceala@gmail.com",
       Subject: "Newsletter",
       Body: "Utilizatorul s-a inscris la newsletter cu email-ul: "+ "\n\n\n\n" + text,
   }).then(function (message) { 
       notifyy();
       // window.history.go(-1);
     });
   
};



export default class Home extends React.Component {
  
 
  
    state = {
          modalIsOpen: false,
          showBlock: false,
          listItemsIsOpen: false,
          currentItem: -1,
          loading: false,
          items: [],
          modalOpen: false
  }



   showModal = () => {
      this.setState({ modalIsOpen: true });
    };
  
   closeModal = () => {
      this.setState({ modalIsOpen: false });
    };
   
    
 

    handleModalOpen = () => {
      this.setState((prevState) => {
         return{
            modalOpen: !prevState.modalOpen
         }
      })
   };

   

   openModal=() => {
     this.setState({modalIsOpen: true});
   };


    
     getData(){
    
            var email = document.getElementById('EMAIL_').value;
          
            newsletterData =
            "\n Email-ul oferit pentru newsletter: " + email;
    
            if(email !== "" && email.includes('@')){
                sendNewsletter(newsletterData);
            }else{
               notifye();
            }
    
            document.getElementById('EMAIL_').value='';
    
    };
    initModal(){
    
      
    }
    render(){     

 



      $(window).scroll(function(){
        
        var threshold = 590; // number of pixels before bottom of page that you want to start fading
        
        var op = (($(document).height() - $(window).height()) - $(window).scrollTop()) / threshold;
        var y = window.scrollY;
      

      
        if( op <= 0 ){
          $("#anunturi").hide();
        } else {
          $("#anunturi").show();
          $("#anunturi").css("visibility","visible");
        }
        $("#anunturi").css("opacity", op ); 

  
            
        
        if(  y < 500){

          $("#anunturi").hide();
        

        }else{
          $("#anunturi").show();
       
        }
      });

 
   
      // $(document).scroll(function() {

      //   var y = $(this).scrollTop();
    
      //   if (y > 800) {
      //     $('#anunturi').fadeIn();
      //   } else {
      //     $('#anunturi').fadeOut();
      //   }

      
      
      
      
//         var p = $( "p" ).first();
// var position = p.position();
// // $( "p" ).last().text( "left: " + position.left + ", top: " + position.top );

//         var objDiv = document.getElementById("anunturi");
//         objDiv.scrollTop = objDiv.scrollHeight;

//         if( objDiv >9000){
//           $('#anunturi').text(position.top);
//         }

        // if(y< 1500){
        //   $('#anunturi').position="top";
        // }

    
           
      // });
          
     
  return (



      <>
      <div id="mobil" >
<ToastContainer
position="top-right"
autoClose={false}

closeOnClick ={false}
link= '/fonduri'
rtl={false}
pauseOnFocusLoss
draggable

/>
</div>
      <div className="main">
        
    <section id="menu-section" data-aos="zoom-in-right">
    {/* <Announcement
    title="Corect Audit aduce NOU Consultanta in Fonduri Europene" 
    subtitle="Vezi mai multe detalii chiar aici"
    imageSource={Logo}
    link="/fonduri"
    daysToLive={1000}
    id="anuntPOP"
    secondsBeforeBannerShows={5}
    animateInDuration={2000}
    animateOutDuration={500}
    
    
/>
 */}





    <div className="content-wrap2" id="video" >
   
      <div className="heading">  
   

      <video className='videoTag' id="videoo" autoPlay loop muted playsInline >
        <source src={mobilv} type='video/mp4' />
      </video>

     
        
       
        <div className="infoTransitions" data-aos="fade-down" data-aos-delay="300">
          
          <img src={Human} id="human" alt="human-corect" />
        
          <img src={Titlu} id="titlu_poza" alt="titlu-corect"/>
          <img src={Anunt} id="anunt" alt="anunt-corect"/> 
          
        </div>
        
       
        
       <div className="pozaTranzitie" style={{ marginTop:'0px'}}>
          <p className="item-1" style={{marginTop: '0px'}}><text style={{backgroundColor: '#ffa86e', textAlign:'center', overflow: 'hidden', borderRadius: '10px' , paddingTop:'10px', paddingBottom:'10px', paddingLeft:'0px',paddingRight:'0px',     animation: ' example 10s ease infinite', fontWeight:'bold' }}>Contabilul tău online, chiar acum! <br></br><button id="btn-det1"  style={{ animation: ' example 10s ease infinite'}}><a href='/oferta' id="cere" target="blank_" rel="noreferrer" >Cere oferta</a></button></text></p>
          <p className="item-2"><text style={{background:'#ffa86e' , textAlign:'center', overflow: 'hidden', borderRadius: '10px' , paddingTop:'10px', paddingBottom:'10px', paddingLeft:'5px',paddingRight:'5px',fontWeight:'bold'}}>Oferim servicii de Consultanță, Analiză si Audit financiar<br></br><button id="btn-det2" ><a href='/oferta' id="cere" target="blank_" rel="noreferrer">Cere oferta</a></button></text></p>              
          <p className="item-3" style={{marginTop: '0px'}}><text style={{background: '#ffa86e', textAlign:'center',overflow: 'hidden', borderRadius: '10px' , paddingTop:'10px', paddingBottom:'10px', paddingLeft:'0px',paddingRight:'0px',fontWeight:'bold'}}>Contactează-ne și vei fi plăcut surprins! Te afli la un click distanță!<br></br><button id="btn-det3" ><a href='/oferta' id="cere" target="blank_" rel="noreferrer">Cere oferta</a></button></text></p>
        </div>
       
        <div className="anunt" onClick = {this.openModal}> 
        <Link to='/fonduri'>

<NewsTicker 
    tickerBorderColor="#A7BFE8"

  //  id = "news"
    title={"Ultimele anunturi"}
    titleBackground={"linear-gradient(141deg, rgb(224 117 0) 0%, rgb(244 156 100) 51%, rgb(255 185 123) 75%)"}
  
    newsList={[
      
        {
          
            text: "NOU!!! Oferim Consultanță Fonduri Europene",
           link: "http://www.corectaudit.ro/fonduri",
            
            // onclick:this.handleModalOpen
           
        },
        {
          text: "Click aici pentru mai multe detalii",
          link: "http://www.corectaudit.ro/fonduri"
      },
        {
            text: "Start înscrieri la sesiunea 2: Microgranturi acordate din fonduri externe nerambursabile în valoare de 2000€",
            link: "https://static.anaf.ro/static/3/Anaf/20211006102344_1422-06.10.2021-%20comunicat%20dgv-intalnire%20de%20lucru.pdf"
        },
        {
            text: "",
            link: ""
        }
    ]}
    // onClick = {this.state.modalOpen}
/>
</Link>

         </div>
         </div>   
         </div>

       </section>
 
       <div>
      
     
        <div className="cardEu">    
   
         </div>
    

    
    </div>
     

<main>
<div id="anunturi"  style={{visibility: 'hidden'}}>


  <div class="containerNews" id="anuntMare">
 
  <div class="news">
  <span class="d-flex align-items-center"><img src={anuntnews} id="anuntnews" alt="news-corect"/></span>
   </div>
 
        
        <p id="par">
            <div class="d-flex justify-content-between align-items-center breaking-news bg-white">
            
             
                <Marquee class="news-scroll" behavior="scroll" direction={'left'} pauseOnHover= {'true'} speed={50}  gradient={false}  > 
                <div class="d-flex flex-row flex-grow-1 flex-fill justify-content-center bg-danger py-2 text-white px-1 news">
                
                </div>
                <Link
                to={{
                  pathname:"/fonduri",
                  state: {modal:true},
                }}
                
                  className ="linkA"

               
                  
                >Corect Audit oferă servicii de consultanță în Fonduri Europene. Completați chiar acum formularul și veți intra instant în legătură cu un specialist.  Vino cu o idee, transpune-o de la A la Z iar noi venim cu soluția bine pusă la punct!  Click chiar aici și trimite-ne toate informațiile.</Link>
                <Link to={{
                  pathname:"/fonduri",
                  state: {modal:true},
                }}
                
                  className ="linkA">Corect Audit oferă servicii de consultanță în Fonduri Europene. Completați chiar acum formularul și veți intra instant în legătură cu un specialist.  Vino cu o idee, transpune-o de la A la Z iar noi venim cu soluția bine pusă la punct!  Click chiar aici și trimite-ne toate informațiile.</Link>
            

            <Link to={{
                  pathname:"/fonduri",
                  state: {modal:true},
                }}
                
                  className ="linkA">Corect Audit oferă servicii de consultanță în Fonduri Europene. Completați chiar acum formularul și veți intra instant în legătură cu un specialist.  Vino cu o idee, transpune-o de la A la Z iar noi venim cu soluția bine pusă la punct!  Click chiar aici și trimite-ne toate informațiile.</Link>
            
            <Link to={{
                  pathname:"/fonduri",
                  state: {modal:true},
                }}
                
                  className ="linkA">Corect Audit oferă servicii de consultanță în Fonduri Europene. Completați chiar acum formularul și veți intra instant în legătură cu un specialist.  Vino cu o idee, transpune-o de la A la Z iar noi venim cu soluția bine pusă la punct!  Click chiar aici și trimite-ne toate informațiile.</Link>
            
                {/* <span class="dot"></span>
                 <a href="#" className="link">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut </a>
                 <span class="dot"></span> 
                 <a href="#">Duis aute irure dolor in reprehenderit in voluptate velit esse </a>  */}
                 </Marquee>
                
            </div>
            </p>
        </div>
    
</div>





 
    <section className ="projects " >
    
       <div className="content-wrap divider" id="servicii">   
        <h2 id="oferim">Ce oferim </h2>
        <p style={{lineHeight: 2, fontWeight: 'normal', paddingLeft: '30px', textIndent:'25px'}}>Mai jos aveti serviciile prezentate succint.  Pentru mai multe informatii, cereti o 
         <a href={'/oferta'} style={{width: '70px'}}><b> oferta</b></a>.</p>
   
    
      <section className = "project-item" id="s1" >
        <img src={Conta} alt="conta" id="conta-im" style={{width: '25% ', borderRadius: '30px'}}/>     
      <div id="contabilitate">
      <h3 id="titluri" style={{marginTop: '40px', textIndent:'20px'}}>Contabilitate</h3>         
      <p style={{textIndent: '8px'}} >Echipa Corect Audit Consulting oferă o gamă completă de servicii de contabilitate și
        fiscalitate, adaptate într-un mod cât mai flexibil necesităților clienților noștri precum
         analiza și înregistrarea documentelor primare conform reglementărilor
        contabile și a necesităților de raportare managerială dar și <a href="/contabilitate">...</a></p>
      <a  className ="btn_projects" href="/contabilitate" target="_blank">Mai mult...</a>
   </div>
    </section>
   
  
  <section className="project-item" id="s2">
    <img src={Audit} alt="audit" id="audit-im" style={{width:'25%', borderRadius:'30px'}}/> 
    <h3 id="titluri"style={{marginTop: '40px', textIndent: '20px'}}>Audit</h3>        
    <p style={{textIndent: '8px'}}>Auditul sporește credibilitatea situațiilor financiare ale companiei.
      Rezultatul activității de audit financiar se materializează printr-un raport de audit
      financiar<a href="/audit">...</a></p>
      <a  className ="btn_projects" href="/audit" target="_blank">Mai mult...</a>
  </section>

  <section className="project-item" id="s3">
    <img src={Analiza} alt="danaliza" style={{width: '25%', borderRadius: '30px'}}/>
    <h3 id="titluri"style={{marginTop: '40px', textIndent: '20px'}}>Analiza financiară</h3>       
     <p style={{textIndent: '8px'}}> Analiza financiară contribuie la cunoașterea continuă a eficienței utilizării resurselor umane, materiale și financiare pentru atingerea obiectivului principal – maximizarea profitului sau în vederea obținerii de finanțări.<a href="/analiza">...</a></p>
     <a  className ="btn_projects" href="/analiza" target="_blank">Mai mult...</a>
  </section>


   
<section className="project-item" id= "s4">
    <img src={Cons} style={{width: '25%',borderRadius:'30px'}}alt="cons"/>
  <h3 id="titluri" style={{marginTop: '40px', textIndent: '20px'}}>Consultanța fiscală </h3>        
  <p style={{textIndent: '8px'}}>Consultanța fiscală este o etapă importantă de care trebuie să țină seama orice conducător al unei unități, indiferent de domeniul în care activează. r<a href="/consultanta">...</a></p>
    <a  className ="btn_projects" href="/consultanta" target="_blank">Mai mult...</a>
</section>

<section className="project-item" id="s4">
  <img src={Sal} alt="danaliza" style={{width:'25%', borderRadius:'30px'}}/>
  <h3 id="titluri"style={{marginTop: '40px', textIndent: '20px'}}>Salarizare</h3>       
   <p style={{textIndent: '8px'}}>Preluarea datelor din evidența anterioară salarială și setarea sistemului
    informatic<a href="/salarizare">...</a></p>
   <a  className ="btn_projects" href="/salarizare" target="_blank">Mai mult...</a>
</section>



</div>
</section>


  {/* <!-- ***********************  Articole  *********************** --> */}
  

<section className="work" >
<h1 className="text-gray-500 font-semibold text-4xl center pr-12 w-full lg:w-6/12 px-4 ml-auto mr-auto"  data-aos="fade-up">
     Știrile la zi 
        </h1>
{/* <section class="col-2 color ss-style-curvedown">
    <div class="column text">
         {/* <h2></h2> *}
    </div>
</section>

<svg id="curveDownColor" xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">
    <path d="M0 0 C 50 100 80 100 100 0 Z" />
</svg>  */}


  

    <div className="job-item " >

<Card disablespacing="true" id="link1" data-aos="fade-down" data-aos-delay="200">

{/* <Iframe url="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fcorectauditro%2Fposts%2F141658974436268&width=350&show_text=false&height=354&appId" width="350" height="354" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
    id="post1"
    wordBreak="break-all"
    idName="myClassname"
    justifyContent ='center'
    display="initial"
    position="relative"/> */}

<CardHeader
      
        title="𝐑𝐞𝐥𝐚𝐧𝐬𝐚𝐫𝐞 𝐌ă𝐬𝐮𝐫𝐚 𝟑 - ”𝐈𝐧𝐯𝐞𝐬𝐭𝐢ț𝐢𝐢 î𝐧 𝐚𝐜𝐭𝐢𝐯𝐢𝐭ăț𝐢 𝐩𝐫𝐨𝐝𝐮𝐜𝐭𝐢𝐯𝐞” 𝟐𝟎𝟐𝟏"
      
        subheader="Octombrie 25, 2021"
        href= {PDF} target="_blank"
        titleTypographyProps={{variant:'h6' }}
      />

    <CardContent>
 
<br></br>
Obiectivul schemei de ajutor de stat îl reprezintă susținerea IMM-urilor în cadrul programului de relansare economică cu finanțare din facilitatea de Finanțare REACT-EU în contextul crizei economice generate de pandemia de COVID-19, prin acordarea de ajutoare temporare cu valoare limitată acelor IMM-uri care se confruntă cu un deficit sau chiar cu indisponibilitatea lichidităților.

<br></br>
<br></br>

Sursă:<b> MIPE </b>
<br></br>
{/* Ți-a plăcut acest articol? */}
{/* Vezi mai mult  */}
Dă <em>𝑳𝒊𝒌𝒆</em>, <em>𝑷𝒓𝒊𝒏𝒕𝒆𝒂𝒛𝒂̆</em> sau trimite pe <em>𝙀-𝙢𝙖𝙞𝙡</em>!
    </CardContent>
<CardActions disablespacing="true">
    
    <Button size="small" style={{marginTop:'20px', marginLeft:'40px'}}color="primary"onClick={() => window.open(PDF)}>
       Vezi aici principalele condiții de finanțare
    </Button>
    

      <>
{[ 'bottom', ].map((placement) => (
<OverlayTrigger
  trigger="click"
  key={placement}
  placement={placement}
  
  overlay={
    <Popover id={`popover-positioned-${placement}`} >
      <Popover.Title as="h5">{`Fă-ne cunoscuți`}</Popover.Title>
      <Popover.Content>
        
        <div className="targets" style={{display:'inline-block'}}>
     
    <a href="https://www.facebook.com/sharer/sharer.php?u=#corectaudit.ro" >
     <FacebookIcon size={19} round={true} /> 
    </a>

       <a href="https://wa.me/?text=%27𝐑𝐞𝐥𝐚𝐧𝐬𝐚𝐫𝐞 𝐌ă𝐬𝐮𝐫𝐚 𝟑 - ”𝐈𝐧𝐯𝐞𝐬𝐭𝐢ț𝐢𝐢 î𝐧 𝐚𝐜𝐭𝐢𝐯𝐢𝐭ăț𝐢 𝐩𝐫𝐨𝐝𝐮𝐜𝐭𝐢𝐯𝐞” 𝟐𝟎𝟐𝟏- Obiectivul schemei de ajutor de stat îl reprezintă susținerea IMM-urilor în cadrul programului de relansare economică cu finanțare din facilitatea de Finanțare REACT-EU în contextul crizei economice generate de pandemia de COVID-19, prin acordarea de ajutoare temporare cu valoare limitată acelor IMM-uri care se confruntă cu un deficit sau chiar cu indisponibilitatea lichidităților. Mai multe pe https://corectaudit.ro" >
        <WhatsappIcon size={19} round={true}/>
      </a>
        
      {/* <a href="https://twitter.com/share?url=<URL>&text=<TEXT>via=<USERNAME>">
        <TwitterIcon size={19} round={true} />
      </a>
        
      <a  href="https://www.linkedin.com/shareArticle?url=<URL>&title=<TITLE>&summary=<SUMMARY>&source=<SOURCE_URL>">
        <LinkedinIcon size={19} round={true} />
      </a>   
    
      <a href="https://reddit.com/submit?url=<URL>&title=<TITLE>">
        <RedditIcon size={19} round={ true}/>
      </a>  */}
        
      <a href="mailto:?subject=<𝐑𝐞𝐥𝐚𝐧𝐬𝐚𝐫𝐞 𝐌ă𝐬𝐮𝐫𝐚 𝟑 - ”𝐈𝐧𝐯𝐞𝐬𝐭𝐢ț𝐢𝐢 î𝐧 𝐚𝐜𝐭𝐢𝐯𝐢𝐭ăț𝐢 𝐩𝐫𝐨𝐝𝐮𝐜𝐭𝐢𝐯𝐞” 𝟐𝟎𝟐𝟏>&body=<Obiectivul schemei de ajutor de stat îl reprezintă susținerea IMM-urilor în cadrul programului de relansare economică cu finanțare din facilitatea de Finanțare REACT-EU în contextul crizei economice generate de pandemia de COVID-19, prin acordarea de ajutoare temporare cu valoare limitată acelor IMM-uri care se confruntă cu un deficit sau chiar cu indisponibilitatea lichidităților. Mai multe pe https://corectaudit.ro>">
        <EmailIcon size={ 19} round={true}/>
      </a>  
<div className="link">
<div className="pen-url">http://corectaudit.ro</div>
<CopyToClipboard text={"corectaudit.ro"}>    
 <Button className="copy-link" onClick={notify}>Copy Link</Button>  
  </CopyToClipboard>
</div>
</div>

      </Popover.Content>
    </Popover>
  }
>
  
  <IconButton aria-label="share" type="button"  variant="secondary" style={{marginTop:'10px'}}>
      <ShareIcon />
      </IconButton>
</OverlayTrigger>
))}
</>
  </CardActions>

  </Card>


<Card id="link2"  data-aos="fade-down" data-aos-delay="300"> 

          <CardImg variant="top" src={CoverImg} style={{width:'100%'}}  />
          

  <p style={{fontFamily:'Montserrat',backgroundColor: 'antiquewhite', marginTop:'10%'}} id="noutati"><strong>Fii la curent cu ultimele noutăți </strong> </p>
  <br></br>
   <p id="noutati" style={{marginTop:'10%'}}> <strong>Urmărește-ne pe paginile media!</strong></p>

   <CardHeader
   title = "𝐅𝐨𝐫𝐦𝐮𝐥𝐚𝐫𝐮𝐥 𝟎𝟖𝟑. 𝐃𝐨𝐜𝐮𝐦𝐞𝐧𝐭𝐮𝐥 𝐜𝐞 𝐭𝐫𝐞𝐛𝐮𝐢𝐞 𝐜𝐨𝐦𝐩𝐥𝐞𝐭𝐚𝐭 𝐩𝐞𝐧𝐭𝐫𝐮 𝐮𝐭𝐢𝐥𝐢𝐳𝐚𝐫𝐞𝐚 𝐬𝐢𝐬𝐭𝐞𝐦𝐮𝐥𝐮𝐢 𝐑𝐎 𝐄-𝐅𝐚𝐜𝐭𝐮𝐫𝐚"
   subheader= "Noiembrie 22, 2021"
   link=""
   titleTypographyProps={{variant:'h8'}}
   />
   <CardContent>
   In Monitorul Oficial nr. 1096 din 17 noiembrie 2021 a fost publicat Ordinul nr. 1831/2021 al ANAF pentru aprobarea Procedurii privind exercitarea opțiunii de utilizare, de către operatorii economici nerezidenți, a sistemului național privind factura electronică RO e-Factura, precum și a modelului, conținutului și instrucțiunilor de completare ale formularului (083) „Opțiune privind utilizarea, de către operatorii economici nerezidenți, a sistemului național privind factura electronică RO e-Factura“. <a href="https://www.facebook.com/corectauditro/posts/332205955381568">Vezi mai multe aici</a>.
   <br></br>
   Sursa: <b>ANAF</b>
   <br></br>
   Ti-a placut acest articol?
  <em>Da <b>Like</b>, <b>Printeaza</b> sau trimite pe <b>E-mail!</b></em>
  
      </CardContent>
  
    <Button size="small" color="primary" style= {{marginTop:'22px', marginLeft:'40px'}} onClick={() => window.open('https://www.facebook.com/corectauditro/posts/332205955381568')}>
         Vezi postarea originala
    </Button>  

 

  </Card>

  <Card disablespacing="true" id="link3"  data-aos="fade-down" data-aos-delay="300">

<CardHeader
      
        title= "𝐒𝐞𝐬𝐢𝐮𝐧𝐞𝐚 𝟐 𝐚 𝐌ă𝐬𝐮𝐫𝐢𝐢 𝟏 𝐝𝐢𝐧 𝐩𝐫𝐨𝐠𝐫𝐚𝐦𝐮𝐥 : 𝐌𝐢𝐜𝐫𝐨𝐠𝐫𝐚𝐧𝐭𝐮𝐫𝐢 𝐚𝐜𝐨𝐫𝐝𝐚𝐭𝐞 𝐝𝐢𝐧 𝐟𝐨𝐧𝐝𝐮𝐫𝐢 𝐞𝐱𝐭𝐞𝐫𝐧𝐞 𝐧𝐞𝐫𝐚𝐦𝐛𝐮𝐫𝐬𝐚𝐛𝐢𝐥𝐞 î𝐧 𝐯𝐚𝐥𝐨𝐚𝐫𝐞 𝐝𝐞 𝟐𝟎𝟎𝟎 𝐝𝐞 𝐞𝐮𝐫𝐨"
        subheader="Octombrie 25, 2021"
        link= ""
        titleTypographyProps={{variant:'h6' }}
        
      />


    <CardContent>


    Incepând de pe 25 octombrie 2021 s-a dat start la faza de <a href="https://granturi.imm.gov.ro/auth/login">înscriere propriu-zisă (Etapa 2) la Măsura 1</a>, ediția a doua (2021), din cadrul schemei de ajutoare de stat COVID-19 pentru IMM, în care microîntreprinderile (SRL), persoanele fizice autorizate (PFA), întreprinderile individuale (II), cei care desfășoară profesii liberale și alți solicitanți(ONG cu activitate economica) pot cere microgranturi de capital de lucru de câte 2.000 de euro.
   <br></br>
   Sursa: <b>MEAT</b>
   <br></br>
  Ti-a placut acest articol?
  <em>Da <b>Like</b>, <b>Printeaza</b> sau trimite pe <b>E-mail!</b></em>

      </CardContent>
    <CardActions disablespacing="true">
    <Button size="small" color="primary" style= {{marginTop:'22px', marginLeft:'40px'}} onClick={() => window.open('https://www.facebook.com/corectauditro/posts/316010867001077')}>
         Vezi postarea originala
    </Button>       

      <>
{[ 'bottom', ].map((placement) => (
<OverlayTrigger
  trigger="click"
  key={placement}
  placement={placement}
  overlay={
    <Popover id={`popover-positioned-${placement}`}>
      <Popover.Title as="h5">{`Fă-ne cunoscuți!`}</Popover.Title>
      <Popover.Content>
        <div className="targets" style={{display:'inline-block'}}>
     
                <a href="https://www.facebook.com/sharer/sharer.php?u=#corectaudit.ro" >
                  <FacebookIcon size={19} round={true} /> 
                </a>
                < a href="https://wa.me/?text=%27𝐒𝐞𝐬𝐢𝐮𝐧𝐞𝐚 𝟐 𝐚 𝐌ă𝐬𝐮𝐫𝐢𝐢 𝟏 𝐝𝐢𝐧 𝐩𝐫𝐨𝐠𝐫𝐚𝐦𝐮𝐥 : 𝐌𝐢𝐜𝐫𝐨𝐠𝐫𝐚𝐧𝐭𝐮𝐫𝐢 𝐚𝐜𝐨𝐫𝐝𝐚𝐭𝐞 𝐝𝐢𝐧 𝐟𝐨𝐧𝐝𝐮𝐫𝐢 𝐞𝐱𝐭𝐞𝐫𝐧𝐞 𝐧𝐞𝐫𝐚𝐦𝐛𝐮𝐫𝐬𝐚𝐛𝐢𝐥𝐞 î𝐧 𝐯𝐚𝐥𝐨𝐚𝐫𝐞 𝐝𝐞 𝟐𝟎𝟎𝟎 𝐝𝐞 𝐞𝐮𝐫𝐨- Incepând de astazi, 25 octombrie 2021, ora 10.00, începe faza de <a href='https://granturi.imm.gov.ro/auth/login'>înscriere propriu-zisă (Etapa 2) la Măsura 1</a>, ediția a doua (2021), din cadrul schemei de ajutoare de stat COVID-19 pentru IMM, în care microîntreprinderile (SRL), persoanele fizice autorizate (PFA), întreprinderile individuale (II), cei care desfășoară profesii liberale și alți solicitanți(ONG cu activitate economica) pot cere microgranturi de capital de lucru de câte 2.000 de euro..Mai multe detalii pe https://corectaudit.ro">
                  <WhatsappIcon size={19} round={true}/>
                </a>                      
                {/* <a href="https://twitter.com/share?url=<URL>&text=<TEXT>via=<USERNAME>">
                  <TwitterIcon size={19} round={true} />
                </a>                      
                <a  href="https://www.linkedin.com/shareArticle?url=<URL>&title=<TITLE>&summary=<SUMMARY>&source=<SOURCE_URL>">
                  <LinkedinIcon size={19} round={true} />
                </a>                   
                <a href="https://reddit.com/submit?url=<URL>&title=<TITLE>">
                  <RedditIcon size={19} round={ true}/>
                </a>                        */}
                <a href="mailto:?subject=<𝐒𝐞𝐬𝐢𝐮𝐧𝐞𝐚 𝟐 𝐚 𝐌ă𝐬𝐮𝐫𝐢𝐢 𝟏 𝐝𝐢𝐧 𝐩𝐫𝐨𝐠𝐫𝐚𝐦𝐮𝐥 : 𝐌𝐢𝐜𝐫𝐨𝐠𝐫𝐚𝐧𝐭𝐮𝐫𝐢 𝐚𝐜𝐨𝐫𝐝𝐚𝐭𝐞 𝐝𝐢𝐧 𝐟𝐨𝐧𝐝𝐮𝐫𝐢 𝐞𝐱𝐭𝐞𝐫𝐧𝐞 𝐧𝐞𝐫𝐚𝐦𝐛𝐮𝐫𝐬𝐚𝐛𝐢𝐥𝐞 î𝐧 𝐯𝐚𝐥𝐨𝐚𝐫𝐞 𝐝𝐞 𝟐𝟎𝟎𝟎 𝐝𝐞 𝐞𝐮𝐫𝐨>&body=<Incepând de astazi, 25 octombrie 2021, ora 10.00, începe faza de <a href='https://granturi.imm.gov.ro/auth/login'>înscriere propriu-zisă (Etapa 2) la Măsura 1</a>, ediția a doua (2021), din cadrul schemei de ajutoare de stat COVID-19 pentru IMM, în care microîntreprinderile (SRL), persoanele fizice autorizate (PFA), întreprinderile individuale (II), cei care desfășoară profesii liberale și alți solicitanți(ONG cu activitate economica) pot cere microgranturi de capital de lucru de câte 2.000 de euro..Mai multe detalii pe https://corectaudit.ro">
                  <EmailIcon size={ 19} round={true}/>
                </a>  
<div className="link">
<div className="pen-url">http://corectaudit.ro</div>
<CopyToClipboard text={"corectaudit.ro"}>    
     <Button className="copy-link" onClick={notify}>Copy Link</Button>       
</CopyToClipboard>

</div>
</div>  
      </Popover.Content>
    </Popover>
  }
>
  {/* <Button variant="secondary">Popover on </Button> */}
  <IconButton aria-label="share" type="button" style= {{marginTop:'22px'}}  variant="secondary">
      <ShareIcon />
      </IconButton>
</OverlayTrigger>
))}
</>
 
  </CardActions>
  </Card>


  <Card disablespacing="true" id="link4"  data-aos="fade-down" data-aos-delay="300">
{/* <Iframe url="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fcorectauditro%2Fposts%2F137578648177634&width=500&show_text=true&height=370&appId"
    id="post2"
    className="myClassname"
    display="initial"
    position="relative"
    /> */}


<CardHeader
      
        title= "𝐃𝐞𝐜𝐥𝐚𝐫𝐚ț𝐢𝐚 𝐢𝐧𝐟𝐨𝐫𝐦𝐚𝐭𝐢𝐯ă 𝐃𝟒𝟎𝟔-𝐅𝐢ș𝐢𝐞𝐫𝐮𝐥 𝐬𝐭𝐚𝐧𝐝𝐚𝐫𝐝 𝐝𝐞 𝐜𝐨𝐧𝐭𝐫𝐨𝐥 𝐟𝐢𝐬𝐜𝐚𝐥"
        subheader="Noiembrie 22, 2021"
        link= ""
        titleTypographyProps={{variant:'h6' }}
       
      />


    <CardContent>


    Prin Ordonanța Guvernului nr. 11/2021 pentru modificarea și completarea Legii nr. 207/2015 privind Codul de procedură fiscală și pentru reglementarea unor măsuri fiscale (OG nr. 11/2021) a fost introdusă în sarcina contribuabililor obligația depunerii fișierului standard de control fiscal SAF-T începând cu 1 ianuarie 2022.
Fișierul standard de control fiscal (SAF-T) devine obligatoriu de anul viitor, însă etapizat și diferit în funcție de categoria din care contribuabilii fac parte, astfel:
<br></br>➖contribuabilii aflați deja în categoria marilor contribuabili – de la data de 1 ianuarie 2022 (data de referință pentru marii contribuabili);
<br></br>➖contribuabilii aflați deja în categoria contribuabililor mijlocii - pe parcursul anului  2022;
<br></br>➖contribuabilii aflați deja în categoria micilor contribuabili - pe parcursul anului 2023.
Contribuabilii vor avea obligația de a depune la organul fiscal Fișierul SAF-T sub forma unei declarații informative în format electronic care <a href="https://www.facebook.com/corectauditro/posts/332204192048411">...vezi mai multe aici</a>.
   <br></br>
   Sursa: <b>ANAF</b>
   <br></br>
   Ti-a placut acest articol?
  <em>Da <b>Like</b>, <b>Printeaza</b> sau trimite pe <b>E-mail!</b></em>
  
      </CardContent>
    <CardActions disablespacing="true">
    <Button size="small" color="primary" style= {{marginTop:'22px', marginLeft:'40px'}} onClick={() => window.open('https://www.facebook.com/corectauditro/posts/332204192048411')}>
         Vezi postarea originala
    </Button>       

      <>
{[ 'bottom', ].map((placement) => (
<OverlayTrigger
  trigger="click"
  key={placement}
  placement={placement}
  overlay={
    <Popover id={`popover-positioned-${placement}`}>
      <Popover.Title as="h5">{`Fă-ne cunoscuți!`}</Popover.Title>
      <Popover.Content>
        <div className="targets" style={{display:'inline-block'}}>
     
                <a href="https://www.facebook.com/sharer/sharer.php?u=#corectaudit.ro" >
                  <FacebookIcon size={19} round={true} /> 
                </a>
                < a href="https://wa.me/?text=%27𝐃𝐞𝐜𝐥𝐚𝐫𝐚ț𝐢𝐚 𝐢𝐧𝐟𝐨𝐫𝐦𝐚𝐭𝐢𝐯ă 𝐃𝟒𝟎𝟔-𝐅𝐢ș𝐢𝐞𝐫𝐮𝐥 𝐬𝐭𝐚𝐧𝐝𝐚𝐫𝐝 𝐝𝐞 𝐜𝐨𝐧𝐭𝐫𝐨𝐥 𝐟𝐢𝐬𝐜𝐚𝐥. Mai multe detalii pe https://corectaudit.ro">
                  <WhatsappIcon size={19} round={true}/>
                </a>                      
                {/* <a href="https://twitter.com/share?url=<URL>&text=<TEXT>via=<USERNAME>">
                  <TwitterIcon size={19} round={true} />
                </a>                      
                <a  href="https://www.linkedin.com/shareArticle?url=<URL>&title=<TITLE>&summary=<SUMMARY>&source=<SOURCE_URL>">
                  <LinkedinIcon size={19} round={true} />
                </a>                   
                <a href="https://reddit.com/submit?url=<URL>&title=<TITLE>">
                  <RedditIcon size={19} round={ true}/>
                </a>                        */}
                <a href="mailto:?subject=<𝐃𝐞𝐜𝐥𝐚𝐫𝐚ț𝐢𝐚 𝐢𝐧𝐟𝐨𝐫𝐦𝐚𝐭𝐢𝐯ă 𝐃𝟒𝟎𝟔-𝐅𝐢ș𝐢𝐞𝐫𝐮𝐥 𝐬𝐭𝐚𝐧𝐝𝐚𝐫𝐝 𝐝𝐞 𝐜𝐨𝐧𝐭𝐫𝐨𝐥 𝐟𝐢𝐬𝐜𝐚𝐥>&body=<“Mai multe detalii pe https://corectaudit.ro">
                  <EmailIcon size={ 19} round={true}/>
                </a>  
<div className="link">
<div className="pen-url">http://corectaudit.ro</div>
<CopyToClipboard text={"corectaudit.ro"}>    
     <Button className="copy-link" onClick={notify}>Copy Link</Button>       
</CopyToClipboard>

</div>
</div>  
      </Popover.Content>
    </Popover>
  }
>
  {/* <Button variant="secondary">Popover on </Button> */}
  <IconButton aria-label="share" type="button" style= {{marginTop:'22px'}}  variant="secondary">
      <ShareIcon />
      </IconButton>
</OverlayTrigger>
))}
</>
 
  </CardActions>
  </Card>
           
           </div>
   
           
   
{/* 
           <svg id="curveDownColor" xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="0%" viewBox="0 0 100 100" preserveAspectRatio="none">
    <path d="M0 0 C 50 100 80 100 100 0 Z" />
</svg>  */}


           </section>
           
           {/* <svg id="curveUpColor" xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100"
 viewBox="0 0 100 100" preserveAspectRatio="none">
    <path d="M0 100 C 100 100 70 0 200 100 Z" />
</svg> */}
  
  <ToastContainer
     
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      
/>

<Despre/>
    {/* <h2  id="articol_recent">CELE MAI RECENTE ARTICOLE </h2> */}
    

  {/* <!-- ******************  despre ****************** --> */}

{/* 
 <div id="pozeC" >
 <img src={despre} id="despre_img"></img>
 <img src={despre} id="despre_img"></img>
 <img src={despre} id="despre_img"></img>
 <img src={despre} id="despre_img"></img>
</div> */}
{/* <section id="despreS"> */}
{/* <svg id="curveDownColor" xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">
    <path d="M0 0 C 50 100 80 100 100 0 Z" />
</svg>  */}

 {/* <div  id="despreContent"> */}
{/* <aside id="desen1"> */}
{/* <img src={FImg} id="f1" style={{width:'30%', height: 'auto' ,float:'left'}} />    */}
{/* </aside> */}

{/* 

<video src={Vdespre} id="videoD"  style={{float:'left' ,width: '100%'}}loop autoPlay></video> */}

{/* <aside id="desen2"> */}
  {/* <img src={FImg} id="f2"  style={{width:'27%', height: 'auto',float:'right'}}></img> */}
  {/* </aside> */}
   {/* <div className="content-wrap1 item-details"> */}
 


 
    {/* <h2 id="despre_noi">Despre noi</h2> */}
    {/* <div className ="card-container" > */}
  
          {/* <div id="despre_noi">
          </div> */}

              {/* <div className="card" >
   
                <div id ="text_despre">
                <h3 id="cand">Corect Audit Consulting  a luat nastere in anul 2019 </h3>
                 <br></br>
              
                <p id="d_cor" >
                 
               
                 Corect Audit Consulting SRL este membră a Corpului Experților Contabili și Contabililor Autorizați din România (CECCAR), Camere, Camerei Auditorilor Financiari din România (CAFR) si a Autoritatii pentru Supravegherea Publica a activitatii de audit statutar (ASPAAS).
               <br></br> Scopul pentru care a fost infiintata aceasta societate este unul foarte simplu, si anume, sa creasca alaturi de clientii sai si sa reuseasca sa obtina calificativul de excelenta in servicii de contabilitate si audit. 
                <br></br> Corect Audit Consulting SRL isi doreste sa fie alaturi de clientii sai nu doar din punct de vedere fiscal ci si sa construiasca parteneriate care sa genereze plus valoare pentru ambele parti.
                  
                </p>
                  <p id="profi">
               <b> <em> Echipa</em></b>  <br></br>  
                                    
               Profesionistii contabili pe care vi-i punem la dispozitie, au calitati esentiale:
              </p>
                <ul id="criterii">
                 <li>stiinta si competenta ;</li>
                <li> profesionalism;</li>
                <li> moralitate si integritate;</li>
                 <li> flexibilitate si informare;</li>
                 <li> comunicare;</li>
                 <li> independenta si confidentialitate.</li>
                 </ul>
          
                  <p id="profi">
                    <br></br>
                  Administratorul si directorul societatii este expert contabil si auditor financiar, cu o experienta de peste 20 de ani in asigurarea de servicii financiar-contabile si fiscale in numeroase domenii de activitate  si 13 ani in domeniul auditului.
                  <br></br><strong>
                    <br></br>Toate acestea duc la formarea unui colectiv capabil sa ofere valoare afacerii clientilor nostri!</strong>
                  <br></br>
                  </p>
                 
                  </div>

                </div>
 */}


      {/* </div> */}
      {/* </div> */}
{/* </section> */}
   

     {/* </div> */}
                        {/* <img src={despre} id="despre_img"></img>
                        <img src={despre} id="despre_img"></img>
                        <img src={despre} id="despre_img"></img>
                       <img src={despre} id="despre_img"></img>    */}



 
</main>

</div>
</>
   );
}
   
}



