import React, { useState } from 'react';
// import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Dropdown from './Dropdown';
import { faCaretDown} from '@fortawesome/free-solid-svg-icons';
import { } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logoo from './images/logo_png.png';
import { HashLink as HLink } from 'react-router-hash-link';
// import {HashLink as Link} from 'react-router-hash';


function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => {
    setClick(!click);
    // if(document.getElementById("fas fa-bars").style.visibility == 'visible'){
    //   document.getElementById("fas fa-bars").style.visibility = 'hidden';
    //   document.getElementById("fas fa-times").style.visibility = 'visible';
    // }else{
    //   document.getElementById("fas fa-bars").style.visibility = 'visible';
    //   document.getElementById("fas fa-times").style.visibility = 'hidden';
    // }
  };
  
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  return (
    <>
      <nav className='navbar'>
        <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
          <img src={Logoo} id="profil" alt="logo-corect"/>
          {/* <i class='fab fa-firstdraft' />  */}
        </Link>
        <div className='menu-icon' onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          {/* <FontAwesomeIcon icon={faTimes} style={{color:'white' }} className={'fas fa-times'} />
          <FontAwesomeIcon icon={faBars} style={{color:'white'}} className={'fas fa-bars'} /> */}
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
      
          <li className='nav-item'>
          
            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
              Acasă
            </Link>
          </li>
          <li
            className='nav-item'
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            
            <HLink
              to='/#servicii'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Servicii 
         
            </HLink>
           
            {dropdown && <Dropdown /> }     <div>
    <FontAwesomeIcon icon={faCaretDown} style={{color:'white'}} />
  </div> 
          </li>
          <li className='nav-item'>
            <HLink
              to='/#despre_noi' 
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Despre
            </HLink>
          </li>
          <li className='nav-item'>
            <HLink
              to='/#footerImg'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Contactează-ne
            </HLink>
          </li>
          <li>
            {/* <Link
              to='/sign-up'
              className='nav-links-mobile'
              onClick={closeMobileMenu}
            >
              Intra in cont
            </Link> */}
          </li>
        </ul>
        {/* <Button id='btn' /> */}
      </nav>
    </>
  );
}

export default Navbar;