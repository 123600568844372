import React, { Component } from 'react';
import './style/design_audit.css';
import './style/oferta.css'
 import {Helmet} from 'react-helmet';
 import { ToastContainer, toast } from 'react-toastify';
 import 'react-toastify/dist/ReactToastify.css';
 import { Link } from 'react-router-dom';
var printError = (elemId,hintMsg) =>{
    document.getElementById(elemId).innerHTML = hintMsg;
};
let  { numeErr, prenErr , emailErr, telErr} = true;

function closePopUp(){
    document.getElementById("popup").style.display = "none";
}


 
const customId = "custom-id-yes";
const customIdd = "custom-id-yess";
const customIddd = "custom-id-yesss";
const notify = () => toast.error("Verificati toate campurile inainte de a continua!", {
  toastId: customId
});

const notifyy = () => toast.warn("Va rugam sa confirmati ca ati citit Termenii si Politica de confidentialitate!", {
    toastId: customIdd,
    delay:2000
  });
  const notifyyy = () => toast.success("Cererea dumneavoastra a fost inregistrata cu succes!", {
    toastId: customIddd,
    position: "top-center"
    
  });
  


export default class Oferta extends Component{
    
    constructor(props) {
        super(props);
    
        this.state = {
          customer: {
              domeniu: props.domeniu,
             numesoc:props.numesoc,
             nr_sal:props.nr_sal,
            nume: props.nume,
            pren_: props.pren_,
            email_: props.email_,
            tel: props.tel,
            subject:props.subject
          }
        }
  
      }
     

   
    validateForm() {
        
    var numesoc = document.contactForm.numesoc.value;
    var nume = document.contactForm.nume_.value;
    var pren_ = document.contactForm.pren_.value;
    var email_ = document.contactForm.email_.value;
    var tel = document.contactForm.tel.value;
    let checkbox = false;
    var nr_sal= document.getElementById("nr_sal").value;
    var subject=document.getElementById("subject").value;
    var domeniu=document.getElementById("domeniu").value;
    // var domenii = [];
    // var checkboxes = document.getElementsByName("domeniu");

    
    var  numesocErr  = numeErr = prenErr = emailErr= telErr=true;
    
    //Validate numesoc
    if(numesoc === "") {
        
        printError("numesocErr", "Completeaza numele societatii");
    } else {
        var regex = /^[0-9a-zA-Z\s]+$/;                
        if(regex.test(numesoc) === false) {
            printError("numesocErr", "Completeaza cu un nume valid");
        } else {
            printError("numesocErr", "");
           numesocErr = false;
        }
    }

    //Validate pren
    if(pren_ === "") {
        printError("prenErr", "Completeaza prenumele");
    } else {
         regex = /^[a-zA-Z\s]+$/;                
        if(regex.test(pren_) === false) {
            printError("prenErr", "Completeaza cu un prenume valid");
        } else {
            printError("prenErr", "");
           prenErr = false;
        }
    }
    // Validate name
    if(nume === "") {
        printError("numeErr", "Completeaza numele");
    } else {
         regex = /^[a-zA-Z\s]+$/;                
        if(regex.test(nume) === false) {
            printError("numeErr", "Completeaza cu un nume valid");
        } else {
            printError("numeErr", "");
           numeErr = false;
        }
    }
    
    // Validate email address
    if(email_ === "") {
        printError("emailErr", "Completeaza email-ul");
    } else {
         regex = /^\S+@\S+\.\S+$/;
        if(regex.test(email_) === false) {
             printError("emailErr", "Completeaza cu o adresa de email valida");
           
             window.history.go(-1);
        } else{
            printError("emailErr", "");
            emailErr = false;
        }
    }
    
    if(tel === "") {
        printError("telErr", "Completeaza telefonul tau");
    } else {
         regex = /^[0-9]+$/;                
        if(regex.test(tel) === false ){//|| tel[0] !== '0' || tel[1] !== '7' || tel.length !== 10) {
            printError("telErr", "Completeaza cu un numar de telefon valid");
        } else{
            if(tel[0] !== '0' || tel[1] !== '7'){
                printError("telErr", "Numarul trebuie sa inceapa cu '07'");
            }else{
                if(tel.length !== 10){
                    printError("telErr","Numarul trebuie sa fie format din 10 cifre, nu din " + tel.length + " cifre");
                }else{
                    printError("telErr", "");
                   telErr = false;
                  
                }
            } 
        }
    }

    if(document.getElementById('agree').checked){
        checkbox = true;
     }else { 
        //  alert('Va rugam sa confirmati ca ati citit Termenii si Politica de confidentialitate!'); 
        notifyy();
    }
    

    
    if((numesocErr || numeErr || prenErr || emailErr || telErr) === false && checkbox === true) {
     
        if( document.getElementById("popup").style.display="block"){
      
            var dataPreview = 
            "Domeniul de activitate: \n" + domeniu + "\n\n" +
            "Numele societatii: \n" + numesoc + "\n\n" +
             "Nr. salariati:  \n" + nr_sal + "\n\n" +
            "Numele tau: \n" + nume + "\n\n" +
            "Prenumele tau: \n" + pren_ + "\n\n" +
            "Email-ul tau: \n" + email_ + "\n\n" +
            "Telefonul tau: \n" + tel + "\n\n"+
            "Mesajul tau: \n" + subject +"\n\n\n";

        var popup_text= document.getElementById("populate");
        popup_text.value = dataPreview;
        
        }
        else{
            alert("Nu se poate");
        }
     } else {
        //  alert("Verificati toate campurile inainte de a continua!");
    
       notify();
   
     }
    };
    
  
    
    
    sendEmail() {
        // let Email = { send: function (a) { return new Promise(function (n, e) { a.nocache = Math.floor(1e6 * Math.random() + 1), a.Action = "Send"; var t = JSON.stringify(a); Email.ajaxPost("https://smtpjs.com/v3/smtpjs.aspx?", t, function (e) { n(e) }) }) }, ajaxPost: function (e, n, t) { var a = Email.createCORSRequest("POST", e); a.setRequestHeader("Content-type", "application/x-www-form-urlencoded"), a.onload = function () { var e = a.responseText; null != t && t(e) }, a.send(n) }, ajax: function (e, n) { var t = Email.createCORSRequest("GET", e); t.onload = function () { var e = t.responseText; null != n && n(e) }, t.send() }, createCORSRequest: function (e, n) { var t = new XMLHttpRequest; return "withCredentials" in t ? t.open(e, n, !0) : "undefined" != typeof XDomainRequest ? (t = new XDomainRequest).open(e, n) : t = null, t } };
        var numesoc = document.contactForm.numesoc.value;
        var nume = document.contactForm.nume_.value;
        var pren_ = document.contactForm.pren_.value;
        var email_ = document.contactForm.email_.value;
        var tel = document.contactForm.tel.value;
        var nr_sal= document.getElementById("nr_sal").value;
        var subject=document.getElementById("subject").value;
        var domeniu=document.getElementById("domeniu").value;
        // let checkbox = false;
        var dataPreview = 
        "Domeniul de activitate: \n" + domeniu + "\n\n" +
        "Numele societatii: \n" + numesoc + "\n\n" +
         "Nr. salariati:  \n" + nr_sal + "\n\n" +
        "Numele tau: \n" + nume + "\n\n" +
        "Prenumele tau: \n" + pren_ + "\n\n" +
        "Email-ul tau: \n" + email_ + "\n\n" +
        "Telefonul tau: \n" + tel + "\n\n"+
        "Mesajul tau: \n" + subject +"\n\n\n";
      window.Email.send({
            SecureToken: "1c30e605-e052-4676-8d2e-61bdab667885",
            To:'mariaflavia8288@gmail.com, cristina.ciubuc@corectaudit.ro',
            From: "fonceala@gmail.com",
            Subject: "no-reply",
            Body: "Cererea dumneavoastra a fost inregistrata!"+ "\n\n\n\n" + dataPreview,
        }).then(function (message) { 
            // alert("Cererea dumneavoastra a fost inregistrata cu succes!");
             notifyyy();
          
        //   window.history.go(-1);
            
          });
    }




    render(){
        
        return(
            <>
            
            {/* <h2 style={{textIndent: 140}}>CERERE OFERTA </h2>  <div id="myclock" style={{marginLeft: 350}} /> */}
            <Link to="/" className="previous" id="prev">
                 Inapoi</Link> 
  {/* <a href="index.html" className="previous" id="prev">« Inapoi</a> */}
  <div className="container_of">
    <form name="contactForm" method="post">
      <label htmlFor="domeniu">Domeniul de activitate</label>
      <select id="domeniu" name="domeniu" value={this.state.customer.domeniu} style={{fontSize:'100%'}}>
        <option value="contabilitate">Contabilitate</option>
        <option value="audit">Audit</option>
        <option value="analiza_financiara">Analiza financiara</option>
        <option value="consultanta_fiscala">Consultanta fiscala</option>
        <option value="salarizare">Salarizare</option>
        <option value="altceva">Altceva (mentionez mai jos) </option>
      </select>
      <label htmlFor="nume_soc">Numele societatii</label>
      <input type="text" id="nume_soc" name="numesoc" value={this.state.customer.numesoc} placeholder="Numele societatii.." />
      <div className="error" id="numesocErr" />
      <label htmlFor="nr_sal">Numarul de salariati</label>
      <br></br>
      <input type="number" id="nr_sal" name="numar" value={this.state.customer.nr_sal} placeholder="Numarul de salariati.." />
      <br /><br />
      <label htmlFor="nume">Nume</label>    
      <input type="text" id="nume" name="nume_" value={this.state.customer.nume} placeholder="Numele tau.." />
      <div className="error" id="numeErr" />
      <label htmlFor="pren">Prenume</label>
      <input type="text" id="pren" name="pren_" value={this.state.customer.pren_} placeholder="Prenumele tau.." />
      <div className="error" id="prenErr" />
      <label htmlFor="email">Email-ul tau prin care vom comunica:</label>
      <input type="email" id="email" name="email_" value={this.state.customer.email_} placeholder="Ex.office@corectaudit.ro" />
      <div className="error" id="emailErr" />
      <label htmlFor="tel">Numarul tau de telefon</label>
      <input type="tel" id="tel" name="tel__" placeholder="Telefonul tau..." value={this.state.customer.tel} pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required />
      <div className="error" id="telErr" />
      <br /><br />
      <label htmlFor="subject"> Cu ce putem ajuta?  <strong>(optional)</strong></label>
      <textarea id="subject" name="subject" placeholder="Scrie aici daca vrei sa ne dai si alte detalii..." value={this.state.customer.subject} style={{height: 200, fontStyle: 'italic'}} defaultValue={""} />
      <input type="checkbox" name="checkbox" defaultValue="check" id="agree" /> Am citit si sunt de acord cu Termenii si <a href="/#pol" target="_blank" rel="noreferrer" id="popuptrigger" onClick={this.myFunction} style={{backgroundColor: 'rgb(95, 95, 255)'}}> Politica de confidentialitate</a>
      <div className="modal" id="add-modal">
        <div className="popup_content">
          <p style={{marginBottom: 100, marginTop: '-100px'}}>
          </p><h4 style={{marginTop: 0}}>Prelucrarea datelor personale</h4>
          Pe data de 25 mai 2018, Regulamentul 2016/679/UE privind protectia persoanelor fizice in ceea ce priveste prelucrarea datelor cu caracter personal si privind libera circulatie a acestor date (in continuare "GDPR") va fi aplicat de toate statele Uniunii Europene.
          Dorim sa va informam cum ne insusim prevederile Regulamentului si cum protejam datele dumneavoastra personale.
          De asemenea va asiguram ca CORECT AUDIT CONSULTING SRL inregistrata la Registrul Comertului cu datele "zzzzzzzzzz" va continua sa prelucreze datele personale ale clientilor doar in scopuri bine definite si doar in cazul in care clientii si-au exprimat optiunea pentru acest lucru.
          CORECT AUDIT CONSULTING SRL are obligatia de a administra, in conditii de siguranta si numai pentru scopurile specificate, orice date cu caracter personal.
          <strong> 1. Ce sunt datele personale si cum le obtinem?</strong>
          Datele personale sunt acele informatii prin care un client poate fi identificat in mod direct sau indirect. CORECT AUDIT CONSULTING SRL poate obtine aceste date astfel:
          <ul style={{textIndent: 0, paddingLeft: 4}}><strong>Direct de la client</strong>
            <p style={{textIndent: 0, marginTop: 0}}> Sunt de regula acele informatii furnizate la inceputul colaborarii atunci cand deveniti clientul nostru sau cand folositi produsele sau serviciile noastre (la semnarea contractului sau pe parcursul desfasurarii acestuia):</p>
            <li>Nume, prenume, data nasterii</li>
            <li> CNP</li>
            <li> Adresa postala si de e-mail</li>
            <li> Numar de telefon si altele</li> 
          </ul>
          <ul style={{textIndent: 0, paddingLeft: 4}}><strong> Automat</strong></ul>
          <p style={{textIndent: 0, marginTop: 0}}> In anumite situatii, CORECT AUDIT CONSULTING SRL poate colecta datele clientilor si prin mijloace automate. De exemplu, date personale pot fi colectate prin:</p>
          <li> intermediul cookie-urilor (vezi politica de utilizare cookie-uri)</li>
          <li> cand viziteaza site-ul web</li>
          Datele astfel colectate pot include: adresa IP, tipul de browser, sistemul de operare, numarul de identificare al dispozitivului mobil folosit, locatia geografica, adrese URL de redirectionare, informatiile despre actiunile intreprinse.
          Din surse externe
          Pentru oferirea de produse si servicii, Compania poate obtine date personale si din surse precum: parteneri sau asociati (daca ati acordat permisiunea acestora de a a face schimb de informatii cu noi in ceea ce priveste informatiile cu caracter personal), surse publice si site-uri de retele sociale ale unor terti, platforme online (daca exista acordul clientului ca datele sale personale sa ne fie comunicate).
          CORECT AUDIT CONSULTING SRL poate combina mai multe tipuri de date personale ale clientului, provenite din mai multe surse doar pentru a deservi interesele clientului si doar cu consimtamantul acestuia.
          2. Ce date personale prelucram?
          Tipurile de date pe care le putem avea daca este cazul:
          Numele, numarul tau de telefon fix si/sau mobil si adresa de e-mail
          Aceste date sunt necesare in cea mai mare parte pentru: raspunsul la anumite solicitari, identificarea provenientei mesajelor
          Datele privind locatia
          O locatie poate fi derivata din adresa IP sau din date cum ar fi codul postal sau numele unui oras
          <strong> Temeiul juridic al prelucrarii</strong>
          Baza legala pentru colectarea si procesarea datelor dumneavoastra este:
          contractul de furnizare sau prestari servicii la care dumneavoastra sunteti parte sau cererea dumneavoastra inainte de incheierea unui contract;
          indeplinirea unei obligatii legale
          interesul legitim al Companiei
          <strong>De ce prelucram datele personale?</strong>
          Prelucram datele personale doar pentru scopuri legitime, precum acela de a furniza produse si servicii din portofoliul nostru sau pentru a oferi asistenta cu privire la anumite produse/servicii, de a va identifica o solicitare in urma careia este necesar sa oferim un raspuns, pentru a va oferi diverse informatii care pot fi utile (informare stadiu solicitare, informatii despre parteneriate, preturi si vouchere de discount )
          Pentru ca este un interes legitim care nu lezeaza drepturile clientului, Compania va prelucra datele personale:
          pentru a solicita feedback cu privire la experienta dvs. sau pentru a evalua gradul de satisfactie privind produsele/serviciile oferite
          imbunatati produsele si serviciile pe care le oferim, prin evaluarea numarului de utilizatori care acceseaza sau utilizeaza produsele si serviciile noastre
          Doar daca exista acordul in acest sens, Compania va prelucra datele personale pentru a va informa cu privire la
          oferte, promotii sau evenimente special
          in scop de marketing
          Nu desfasuram activitati de creare de profile si protejam copiii prin continutul decent si adecvat oricarei varste al site-ului nostru. Verificam, prin mijloacele de care dispunem in mod rezonabil, ca cei care ne contacteaza si ale caror date personale pot astfel ajunge in grija noastra sa aiba peste 18 ani.
          CORECT AUDIT CONSULTING SRL nu prelucreaza date care pot fi considerate sensibile. Asfel, nu solicitam sub nici o forma date biometrice sensibile, informatii bancare, date privind starea de sanatate, date cu privire la istoricul juridic, informatii privind convingerile politice, religioase sau orice alte informatii de natura confidentiala in legatura cu clientii si utilizatorii produselor si serviciilor noastre.
          <br /><br />
          <strong> 5. Pot fi prelucrate datele tale personale de catre terti?</strong>
          In cazul in care clientul isi exprima acordul in acest sens sau exista un interes legitim in ceea ce priveste utilizarea datelor sale personale, CORECT AUDIT CONSULTING SRL va putea comunica aceste date catre terti.
          CORECT AUDIT CONSULTING SRL poate comunica datele personale ale clientului unor terte parti, in urmatoarele situatii si catre urmatoarele entitati:
          furnizorii implicati in mod direct/indirect in furnizarea produselor si prestarea serviciilor ( de transport si curierat);
          autoritatile statului (inclusiv autoritati fiscale)
          contabili, auditori, experti judiciari, avocati sau alti asemenea consilieri externi ai Societatii sau catre terte persoane care furnizeaza produse si servicii companiei (de sisteme IT, prestatorii de servicii de tip emitere facturi, consultanti financiari)
          furnizorii Societatii implicati in mod direct/indirect in procesul de marketing si promovare
          Mentionam ca folosirea imputernicitilor/tertilor este justificata de ratiuni de business, pentru a putea oferi servicii specializate, profesioniste si ca limitam la strictul necesar si justificabil cantitatea si tipul de date transmise catre terti.
          Vom folosi Google, ca un furnizor tert, pentru a analiza traficul si a stoca mesajele. De asemenea vom utiliza Google pentru a afisa reclame in site-ul nostru sau pentru a servi reclamele noastre pe alte site-uri.
          Google utilizeaza cookie-uri precum si ceea ce se numeste un "DART cookie", un cookie ce ii permite sa difuzeze anunturi utilizatorilor pe baza vizitelor pe site-urile participante. Ca urmare a acestui fapt, reclamele pe care le veti observa pe internet, ar putea, in unele cazuri, sa fie bazate pe vizita dumneavoastra pe site-ul nostru. Puteti opta sa nu fiti inclus in acest program (Google Analytics pentru Display Advertising) si puteti personaliza anunturile Google din Reteaua de Display, folosind pagina Google Ads Settings.
          Ca regula, CORECT AUDIT CONSULTING SRL nu va comunica datele personale ale clientului unei terte parti care intentioneaza sa le utilizeze in scopuri de marketing direct, cu exceptia cazului in care exista acordul clientului in acest sens.
          <br /><br />
          <strong> Cat timp pastram si prelucram datele personale?</strong>
          Datele dumneavoastra personale sunt prelucrate pe tot parcursul relatiei noastre contractuale si, dupa finalizarea acesteia, cel putin pe perioada impusa de prevederile legale aplicabile in domeniu, inclusiv, dar fara limitare, la dispozitiile privind arhivarea. In cazul in care legea nu prevede altceva, datele d-voastra vor fi arhivate pe o perioada de 5 ani dupa expirarea relatiei contractuale.
          <strong> 7. Care sunt drepturile clientilor cu privire la prelucrarea datelor personale si cum pot fi ele exercitate?</strong>
          Regulamentul confera persoanelor carora li se prelucreaza date personale o serie de drepturi specifice in ceea ce priveste protectia datelor, iar CORECT AUDIT CONSULTING SRL asigura un mediu care faciliteaza exercitarea:
          Dreptul de informare /acces - inseamna ca aveti dreptul de a obtine o confirmare din partea noastra ca prelucram sau nu datele cu caracter personal care va privesc si, in caz afirmativ, aveti acces la datele respective si la informatiile privind modalitatea in care sunt prelucrate aceste date.
          Dreptul de retrage a consimtamantul - Retragerea nu va afecta legalitatea prelucrarii pe baza consimtamantului acordat inainte de retragere, pentru executarea unui contract, in vederea indeplinirii unei obligatii legale sau in scopul urmaririi intereselor noastre legitime.
          Dreptul la portabilitatea datelor - se refera la dreptul de a primi datele personale intr-un format structurat, utilizat in mod curent de catre noi si care poate fi citit automat, dar si la dreptul ca aceste date sa fie transmise direct altui operator, daca acest lucru este fezabil din punct de vedere tehnic.
          Dreptul la opozitie - vizeaza dreptul dumneavoastra de a va opune prelucrarii datelor personale atunci cand prelucrarea este necesara pentru indeplinirea unei sarcini care serveste unui interes public sau cand are in vedere un interes legitim al operatorului. Atunci cand prelucrarea datelor cu caracter personal are drept scop marketingul direct sau prelucrari efectuate in baza consimtamantului expres, aveti dreptul de a va opune prelucrarii in orice moment.
          Dreptul la rectificare - se refera la corectarea, fara intarzieri nejustificate, a datelor cu caracter personal inexacte. Rectificarea va fi comunicata fiecarui destinatar la care au fost transmise datele, cu exceptia cazului in care acest lucru se dovedeste imposibil sau presupune eforturi disproportionate.
          Dreptul la stergerea datelor ("dreptul de a fi uitat") - inseamna ca aveti dreptul de a solicita stergerea datelor cu caracter personal, fara intarzieri nejustificate, in cazul in care se aplica unul dintre urmatoarele motive: acestea nu mai sunt necesare pentru indeplinirea scopurilor pentru care au fost colectate sau prelucrate; va retrageti consimtamantul si nu exista niciun alt temei juridic pentru prelucrare; va opuneti prelucrarii si nu exista alte motive legitime care sa prevaleze; datele cu caracter personal au fost prelucrate ilegal; datele cu caracter personal trebuie sterse pentru respectarea unei obligatii legale; datele cu caracter personal au fost colectate in legatura cu oferirea de servicii ale societatii informationale.
          Dreptul la restrictionarea prelucrarii - poate fi exercitat in cazul in care persoana contesta exactitatea datelor, pe o perioada necesara verificarii corectitudinii datelor; prelucrarea este ilegala, iar dumneavoastra va opuneti stergerii datelor cu caracter personal, solicitand in schimb restrictionarea.
          Dreptul de a depune o plangere - plangeri referitoare la prelucrarea datelor personale pot fi adresate Autoritatii Nationale de Supraveghere a Prelucrarii Datelor cu Caracter Personal. Pentru exercitarea acestor drepturi, precum si pentru orice intrebari suplimentare cu privire la aceasta informare sau in legatura cu utilizarea de catre Societate a datelor personale, va rugam sa ne contactati, alegand oricare din modalitatile de comunicare descrise mai jos.
          Drepturi suplimentare aferente deciziilor automate - puteti cere si obtine interventia umana cu privire la respectiva prelucrare, va puteti exprima propriul punct de vedere cu privire la aceasta si puteti contesta decizia.
          <strong>8. Cum protejam datele personale si ce se intampla in cazul unui incident?</strong>
          CORECT AUDIT CONSULTING SRL aplica masuri de siguranta adecvate pentru a asigura securitatea datelor personale ale clientului.
          Pentru a garanta siguranta datelor personale, CORECT AUDIT CONSULTING SRL a implementat o serie de masuri de securitate care sunt in conformitate cu standardele industriei.
          Acestea nu acopera acele date personale pe care clientul alege sa le comunice in spatii publice online sau offline.
          De asemenea, am stabilit proceduri si regulamente interne privind limitarea circulatiei datelor personale, precum si pastrarea si arhivarea acestora.
          Am limitat numarul de operatori catre care am externalizat o parte a serviciilor noastre, precum si tipul de date personale care pot fi transmise catre acestia la un minimum.
          Platformele si sistemele in care stocam date in format electronic sunt protejate prin intermediul programelor antivirus si cu parole.
          In cazul unui atac cibernetic avand ca scop furtul sau vandalismul si care ar putea conduce la dezvaluirea sau compromiterea datelor, CORECT AUDIT CONSULTING SRL se obliga sa ia toate masurile necesare pentru documentarea si remedierea incidentului si pentru limitarea consecintelor. De asemenea va informa despre incident atat utilizatorii, cat si Autoritatea Nationala pentru Protectia Datelor in termen de maxim 72 ore din momentul in care am aflat de producerea incidentului.
          <strong>  9. Cum ne puteti contacta?</strong>
          Datele companiei noastre sunt:
          Nr. Reg Com. J40/1079/2016 CUI 35517450
          Pentru intrebari sau solicitari in legatura cu datele personale, ne puteti contacta:
          pe email la adresa contact@netasist.ro
          prin curier sau direct la adresa B-dul Camil Ressu, Nr 34, Sector 3, Bucuresti
          pe mobil/sms la 0720.075.755, 0732.722.000
          <p />
        </div>
        {/* <div class="modal__actions">
    <button class="btn btn--passive">AM INTELES</button>
    
  </div> */}
      </div>
      <br /><br />
      <input type="button" id="submit" onClick={this.validateForm} defaultValue="TRIMITE SOLICITAREA" /> <ToastContainer/>
      {/* <div class=" container page__main"><div><div class="page-header"><h1>Simple sample</h1></div><button class="btn btn-info">Info</button><hr><button class="btn btn-success">Success</button><hr><button class="btn btn-warning">Warning</button><hr><button class="btn btn-danger">Error</button><div class="notification-container notification-container-empty"><span></span></div></div></div> */}
      <div className="modal1 animate" id="popup">
      <Helmet>
          <script src="https://smtpjs.com/v3/smtp.js" type="text/javascript" />
          </Helmet>
        <div className="modal-content" id="boss">
          <span id="close-button" onClick={closePopUp}>×</span>
          <h4 style={{textIndent: 0, fontFamily: 'Cambria, Cochin, Georgia, Times, "Times New Roman", serif'}}>Cererea ta va fi trimisa cu urmatoarele date: </h4>
          <textarea id="populate"  style={{width: '80%', height: '70%'}} readOnly defaultValue={""} />
          <input type="button" id="submit-email" onClick={this.sendEmail}defaultValue="FINALIZEAZA SOLICITAREA" />
         
        </div>
      </div></form>
  </div> 
  {/* <div class="section section-notifications"><div class="alert alert-success fade show" role="alert"><div class="container"><div class="alert-icon"><i class="now-ui-icons ui-2_like"></i></div><strong>Well done!</strong> You successfully read this important alert message.<button type="button" class="close"><span aria-hidden="true"><i class="now-ui-icons ui-1_simple-remove"></i></span></button></div></div><div class="alert alert-info fade show" role="alert"><div class="container"><div class="alert-icon"><i class="now-ui-icons travel_info"></i></div><strong>Heads up!</strong> This alert needs your attention, but it's not super important.<button type="button" class="close"><span aria-hidden="true"><i class="now-ui-icons ui-1_simple-remove"></i></span></button></div></div><div class="alert alert-warning fade show" role="alert"><div class="container"><div class="alert-icon"><i class="now-ui-icons ui-1_bell-53"></i></div><strong>Warning!</strong> Better check yourself, you're not looking too good.<button type="button" class="close"><span aria-hidden="true"><i class="now-ui-icons ui-1_simple-remove"></i></span></button></div></div><div class="alert alert-danger fade show" role="alert"><div class="container"><div class="alert-icon"><i class="now-ui-icons objects_support-17"></i></div><strong>Oh snap!</strong> Change a few things up and try submitting again.<button type="button" class="close"><span aria-hidden="true"><i class="now-ui-icons ui-1_simple-remove"></i></span></button></div></div></div> */}
  {/* <div className="copyright">
    <div className="row">
      <div className="col-sm-12">
        <p className="text-center">Copyright Corect Audit S.R.L. 2021. Toate drepturile rezervate.</p>
      </div>
    </div>
  </div> */}
</>

        )
    }
}