import React from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Contabilitate from './components/Contabilitate';
import Audit from './components/Audit';
import Home from './components/Home';
import Analiza from './components/Analiza';
import Consultanta from './components/Consultanta';
import Salarizare from './components/Salarizare';
import Oferta from './components/Oferta';
import { ToastContainer } from 'react-toastify';
import {  toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './components/Footer'
import './App.css';
import DocumentMeta from 'react-document-meta';
const emailHeaderText =
  "Consultanta Fonduri Europene";
  


var printError = (elemId,hintMsg) =>{
  document.getElementById(elemId).innerHTML = hintMsg;
};
let  { numeErr, prenErr , emailErr, fisErr} = true;




const customId = "custom-id-yes";
const customIdd = "custom-id-yess";
const customIddd = "custom-id-yesss";
const notify = () => toast.error("Verificati toate campurile inainte de a continua!", {
toastId: customId
});


const notifyyy = () => toast.success("Cererea dumneavoastra a fost inregistrata cu succes!", {
  toastId: customIddd,
  position: "top-center"
  
});

const notifyyyy = () => toast.success("Fisierul a fost incarcat cu succes!", {
  toastId: customIddd,
  position: "top-center"
  
});

const meta = {
    name: 'viewport',
    content: {
      width: 'device-width'
    }
  };

  





 
function sendEmail() {
  // let Email = { send: function (a) { return new Promise(function (n, e) { a.nocache = Math.floor(1e6 * Math.random() + 1), a.Action = "Send"; var t = JSON.stringify(a); Email.ajaxPost("https://smtpjs.com/v3/smtpjs.aspx?", t, function (e) { n(e) }) }) }, ajaxPost: function (e, n, t) { var a = Email.createCORSRequest("POST", e); a.setRequestHeader("Content-type", "application/x-www-form-urlencoded"), a.onload = function () { var e = a.responseText; null != t && t(e) }, a.send(n) }, ajax: function (e, n) { var t = Email.createCORSRequest("GET", e); t.onload = function () { var e = t.responseText; null != n && n(e) }, t.send() }, createCORSRequest: function (e, n) { var t = new XMLHttpRequest; return "withCredentials" in t ? t.open(e, n, !0) : "undefined" != typeof XDomainRequest ? (t = new XDomainRequest).open(e, n) : t = null, t } };
  
  var nume = document.getElementById("nume").value;
  var pren_ = document.getElementById("pren").value;
  var email_ =document.getElementById("email").value;  
 var domeniu = document.getElementById("select").value;
 var mesaj = document.getElementById("textarea").value;
 var fis=document.getElementById("fileInput").value;
 var file = document.getElementById("fileInput").files[0];
 
 var filename;
if (fis) {
    var startIndex = (fis.indexOf('\\') >= 0 ? fis.lastIndexOf('\\') : fis.lastIndexOf('/'));
    filename = fis.substring(startIndex);
    if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
        filename = filename.substring(1);
    }
    
}





  var dataPreview = 
  
  "\nNume client: \n" + "<br><b>"+ nume +"</b></br>"+ "\n\n" +
  "<br>Prenume: \n" + "<br><b>"+ pren_ + "</b></br>"+"</br>\n\n" +
  "<br>Email: \n" + "<br><b>" +email_ + "</b></br>"+ "</br>\n\n" +
  "<br>Pe domeniul: \n" +"<br><b>" + domeniu+"</b></br>"+ "</br>\n\n"+
  "<br>Cu mesajul: \n" + "<br><b>"+ mesaj +"</b></br>"+ "</br>\n\n"+
  "<br>Cu fisierul :\n" + "\n</br>";


  // var file = event.target.files[0];
  var reader = new FileReader();
  reader.readAsBinaryString(file);
  reader.onload = function () {
      var dataUri = "data:" + file.type + ";base64," + btoa(reader.result);
  
  window.Email.send({
      SecureToken: "1c30e605-e052-4676-8d2e-61bdab667885",
      To:'mariaflavia8288@gmail.com, cristina.ciubuc@corectaudit.ro',
      From: "fonceala@gmail.com",
      Subject: "no-reply",
     
      emailHeaderText:{emailHeaderText},
      
      Body: 
    "<p><h2> Cerere de la "+ nume +"</h2></p>"+ "\n<h3>\n Datele clientului :\n</h3>"+ '<body> '+dataPreview  + '</body>'+ "\n\n\n\n"+ file.name,
     
      Attachments : [
        {
          name : file.name,
          data : dataUri
   
        }]
     
      
      
    
  }).then(function (message) { 
      //alert("Cererea dumneavoastra a fost inregistrata cu succes!");
       notifyyy();
       

      dataPreview=" ";
    //window.history.go(-1);
      
    });

    
  }
}



function validateForm() {

var nume = document.getElementById("nume").value;
var pren_ = document.getElementById("pren").value;
var email_ = document.getElementById("email").value;
var fis=document.getElementById("fileInput").files;


  numeErr = prenErr = emailErr = fisErr= true;

// Validate name
if(nume === "") {
  printError("numeErr", "Completeaza numele");
  } else {
  var regex = /^[a-zA-Z\s]+$/;                
  if(regex.test(nume) === false) {
      printError("numeErr", "Completeaza cu un nume valid");
  } else {
      printError("numeErr", "");
     numeErr = false;
    }
  }

//Validate pren
if(pren_ === "") {
printError("prenErr", "Completeaza prenumele");
} else {
 regex = /^[a-zA-Z\s]+$/;                
  if(regex.test(pren_) === false) {
      printError("prenErr", "Completeaza cu un prenume valid");
  } else {
      printError("prenErr", "");
    prenErr = false;
  }
}


// Validate email address
if(email_ === "") {
printError("emailErr", "Completeaza email-ul");
} else {
regex = /^\S+@\S+\.\S+$/;
  if(regex.test(email_) === false) {
      printError("emailErr", "Completeaza cu o adresa de email valida");
    
      
  } else{
      printError("emailErr", "");
      emailErr = false;
  }
}


if(fis.length === 0){
  printError("fisErr","Incarca fisier");
}else{
  notifyyy("Fisierul a fost incarcat cu succes");
  fisErr=false;
}

  

  if((numeErr || prenErr || emailErr || fisErr) === false){
    sendEmail();
  }
  
}




const Fonduri = (location) =>{
  const { state = {}} =location;


  
    return(
      
<div className="content-wrap-card">

             
   
    <div className="card_euro">
    
{/* </Modal.Header>
<Modal.Body> */}
<div className="card-body" >
    
<div className="textBox" style={{paddingRight: '5%', maxWidth: '300px', width: '100%', marginRight: '0px'}}>
<Link to="/" style={{zIndex : "1", marginLeft: '-12%', 
    position:'absolute',
    backgroundColor:'#fff',
    width: '54px',
    height: '45px',
    display: 'grid',
    placeItems: 'center',
    fontSize: '1.7rem',
    color:' #2E4052',
    borderRadius: '90%',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.178)',
    cursor: 'pointer',
    textDecorationLine:'none',
  }} >x</Link>
<h4 style={{color: 'black', position:'top', width:'100%', height:'auto', maxWidth:'300px', textAlign: 'center',paddingBottom: '10%'}} >
               <strong>Trimite-ne ideea ta!</strong>
        </h4>
     <text style={{textAlign:'left',  display:'  grid' ,   lineHeight: '1',  boxShadow: '0 20px 30px rgba(0, 0, 0, 0.185)' , paddingLeft: '3%', paddingRight: '3%'}}>
       <strong>Consultanta Fonduri Europene</strong>
      <p> •	Oferim suport tehnico-financiar  pentru accesarea si implementarea proiectelor de finantare din fonduri nerambursabile, atat companii private, persoane fizice, cat si institutii publice </p>
      <p> •	Elaboram documentatia necesara in conformitate cu metodologia specifica de finantare</p>
      <p> •	Intocmim planul de afaceri, bugetele de investitii si dosarele de creditare bancara</p>
    </text>

</div>

<div  class="newsletter-overlay">
    <form class="md-form">

        <label for="input"  style={{marginLeft:'20px', display:'block',margin:'28px 0 0',fontSize:'14px',color:'#5a5a5a'}}>Nume</label>
        <input type="text" id="nume" class="form-control" placeholder="" wtx-context="EACB9511-A80C-40EA-B879-FDCCA75FDBC0" style={{width:'100%',
        padding:'10px',boxSizing:'border-box',fontFamily:'Montserrat,sans-serif',
        transition:'all .3s',borderBottom:'2px solid #bebed2', maxHeight: '12px'}} />
         <div className="error" id="numeErr" />

        <label for="input" style={{marginLeft:'20px', marginBottom:'0px'}}>Prenume</label>
        <input type="text" id="pren" class="form-control" placeholder="" wtx-context="F4807037-CC6C-4277-8B3B-A74051A1FCFC" style={{maxHeight: '12px'}}/>
        <div className="error" id="prenErr" />

        <label for="emailInput" style={{marginLeft:'20px'}}>Email</label>
        <input type="email" id="email" class="form-control" placeholder="" wtx-context="BFD95563-4899-4E2D-86A8-F341A0D05562" style={{maxHeight: '12px'}}/>
        <div className="error" id="emailErr" />

        <div id = "domeniu">
            <select class="mdb-select md-form mb-4 initialized" id="select" style={{overflow: 'scroll'}}>
                <option value="" disabled selected style={{marginLeft:'20px'}}>În ce domeniu doriți să aplicați?</option>
                <option value="Agroturistic">Agroturistic</option>
                <option value="Dezvoltare rurală">Dezvoltare rurală</option>
                <option value="Digitalizare">Digitalizare</option>
 
            </select>
        </div>
        
        <strong style={{marginLeft:'20px'}}>Încărcați fișierul cu planul dvs. de afacere </strong>
        <div class="file-field">
            <div class="btn btn-primary btn-sm float-left ">
                <span>Alege fișier</span>
                
                <input type="file" id="fileInput" wtx-context="C70B2860-750B-4631-892F-B357F21BEF16"   accept=".docx, .pdf, .txt" 
                  
             />
            
            </div>

            <div className="error" id = "fisErr"/>
            {/* <div class="file-path-wrapper">
                <input class="file-path validate" type="text" placeholder="Încarcă fișier" wtx-context="D3390C76-63F1-4D16-BA53-7ED6AC18CCA9" />
            </div> */}
        </div>

  <br></br>
        <label for="textarea"></label>
        <textarea class="form-control md-textarea" id="textarea" placeholder="Mesaj (opțional)" ></textarea>


        <button class="btn btn-outline-info btn-rounded btn-block z-depth-0 my-4 waves-effect" type="button"  onClick={validateForm} id="submittedd" onChange={sendEmail} defaultValue="TRIMITE SOLICITAREA" >Send</button>

        <ToastContainer/>
    </form>

  
   </div>
</div>

    </div>

    </div>
);
};


// ReactDOM.render(
//   <Upload {...Upload}>
//     <Button icon={<UploadOutlined />}>Click to Upload</Button>
//   </Upload>,
//   mountNode,
// );

  

class App extends React.Component {
 
  
  render(){

   
  
  

  return (
   
 
     
          <Router>
             <DocumentMeta {...meta}/>
      <Navbar />
     
      <Switch>
        <Route path='/' exact component={Home} />
        {/* <Route path='/services' component={Services} />
        <Route path='/products' component={Products} />
        <Route path='/sign-up' component={SignUp} /> */}
        <Route path='/contabilitate' component={Contabilitate} />
        <Route path='/audit' component={Audit}/>
        <Route path='/analiza' component={Analiza}/>
        <Route path='/consultanta' component={Consultanta}/>
        <Route path='/salarizare' component={Salarizare}/>
        <Route path ='/oferta' component={Oferta}/>
        <Route path = '/fonduri' component={Fonduri}/>
     
      </Switch>
    
      
        
          <Footer/>
        
          </Router>
   
  

  );

}

}


export default App;
